import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type TextFieldProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

const Input = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
  padding: 16px 30px;
`;

const TextField: FunctionComponent<TextFieldProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  return (
    <Input
      value={value}
      onChange={(event) => onChange(event.target.value)}
      placeholder={placeholder}
    />
  );
};

export default TextField;
