import React from 'react';
import PlaneDetails, { PlaneDetailsProps } from './plane-details';
import { ContentItem } from '../../types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 60px;
  flex-wrap: wrap;
`;

const InnerContainer = styled.div`
  width: 100%;

  @media all and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 45%;
  }
`;

export type FleetProps = ContentItem & {
  headline: string;
  aircrafts: PlaneDetailsProps[];
};

const Fleet = (props: FleetProps) => {
  const { headline, aircrafts } = props;
  return (
    <div
      className={'helio-container'}
      style={{ paddingTop: '80px', paddingBottom: '80px' }}
    >
      <h2>{headline}</h2>
      <Container>
        {aircrafts.map((aircraft) => (
          <InnerContainer key={aircraft._id}>
            <PlaneDetails {...aircraft} />
          </InnerContainer>
        ))}
      </Container>
    </div>
  );
};

export default Fleet;
