import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ContentItem } from '../../types';
import { dualFormatHeader } from './image-caption';
import _ from 'lodash';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export type TwoTextColumnsProps = ContentItem & {
  leftAnchor?: string;
  leftSubHeadline: string;
  leftHeadline: string;
  leftContent: string; // Rich text - hint: use dangerouslySetInnerHTML={{__html: leftContent}} to render

  rightAnchor?: string;
  rightSubHeadline: string;
  rightHeadline: string;
  rightContent: string; // Rich text - hint: use dangerouslySetInnerHTML={{__html: rightContent}} to render
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  padding-top: 20px;
  padding-bottom: 20px;

  @media (min-width: ${({ theme }): string => theme.breakpoints.xl}) {
    grid-template-columns: repeat(2, 1fr);
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const Column = styled.div`
  flex: 1;
  padding: 10px 25px 10px 10px;
  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    padding: 15px 10px;
  }
`;

const Headline = styled.h3`
  color: ${({ theme }): string => theme.colors.teal30};
  font-weight: black;
`;

const SubHeadline = styled.h2`
  color: black;
  font-weight: 900;

  @media (min-width: ${({ theme }): string => theme.breakpoints.xxl}) {
    margin: 20px 0;
  }
`;

const Content = styled.div`
  font-weight: 100;
`;

const TwoTextColumns = (props: TwoTextColumnsProps) => {
  const {
    leftAnchor,
    leftSubHeadline,
    leftHeadline,
    leftContent,
    rightAnchor,
    rightSubHeadline,
    rightHeadline,
    rightContent,
  } = props;
  const _leftHeadline = dualFormatHeader(leftHeadline);
  const _rightHeadline = dualFormatHeader(rightHeadline);

  const [_id] = useState(_.uniqueId('two-text-columns'));
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const q = gsap.utils.selector(containerRef);
    const tl = gsap.timeline();

    tl.from(q(`#${_id}_left-column > *`), {
      autoAlpha: 0,
      x: 20,
      stagger: 0.2,
    });

    tl.from(q(`#${_id}_right-column > *`), {
      autoAlpha: 0,
      x: 20,
      stagger: 0.2,
    });

    ScrollTrigger.create({
      trigger: `#${_id}`,
      animation: tl,
      start: 'top 80%',
      end: 'bottom top',
    });
  }, [_id]);

  return (
    <Container className={'helio-container'} id={_id} ref={containerRef}>
      <Column id={`${_id}_left-column`}>
        {leftAnchor && <div id={leftAnchor}></div>}
        <Headline dangerouslySetInnerHTML={{ __html: _leftHeadline }} />
        <SubHeadline>{leftSubHeadline}</SubHeadline>
        <Content dangerouslySetInnerHTML={{ __html: leftContent }}></Content>
      </Column>

      <Column id={`${_id}_right-column`}>
        {rightAnchor && <div id={rightAnchor}></div>}
        <Headline dangerouslySetInnerHTML={{ __html: _rightHeadline }} />
        <SubHeadline>{rightSubHeadline}</SubHeadline>
        <Content dangerouslySetInnerHTML={{ __html: rightContent }}></Content>
      </Column>
    </Container>
  );
};

export default TwoTextColumns;
