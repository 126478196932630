import React from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { BodyLink, NavLink } from './link-group';
import { ReactComponent as ChevronDown } from '../icons/chevron-down.svg';
import { ReactComponent as ChevronRight } from '../icons/chevron-right.svg';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { ReactComponent as Spacer } from './level-menu-spacer.svg';
import { ReactComponent as MenuCloseMobile } from './menu-close.svg';
import { createPortal } from 'react-dom';
import MenuMobile from './menu-mobile';
import { ContentItem } from '../../types';
import { Link, useNavigate } from 'react-router-dom';

export type LevelMenuItem = {
  title: string;
  link?: ContentItem;
  subMenu?: LevelMenuItem[];
  showAsButton?: boolean;
};

export type LevelMenuProps = {
  menu: LevelMenuItem[];
  open: boolean;
  currentSubMenu: string | undefined;
  setOpen: (open: boolean) => void;
  setCurrentSubMenu: (subMenu?: string) => void;
};

const MenuCloseMobileContainer = styled.div`
  display: block;
  position: fixed;

  top: 50px;
  right: 40px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    display: none;
  }
`;

const LinkContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
`;

const SubMenuButton = styled.div<{ open: boolean; showAsButton?: boolean }>`
  background-color: ${({ theme }) => theme.colors.yellow};
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  padding: ${({ showAsButton }) =>
    showAsButton ? '10px 30px' : '10px 15px 10px 30px'};
  border-radius: 30px;
  font-weight: 600;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
  align-self: start;
  white-space: nowrap;

  svg {
    fill: white;
    height: 24px;
    width: 24px;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    background-color: ${({ theme, open }) =>
      open ? theme.colors.yellow : transparentize(0.05, theme.colors.red)};
  }
`;

const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    position: fixed;
    top: 200px;
    right: 120px;
    width: 410px;
  }
`;

const MenuLink = styled(BodyLink)`
  padding-left: 30px;
  font-size: 14px;
  opacity: 1;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    padding-left: 0;
    font-size: unset;
    opacity: unset;
    font-weight: 100;
  }

  svg {
    fill: white;
  }
`;

const FullWidthLink = styled(MenuLink)`
  display: flex;
  align-items: center;
  font-weight: bold !important;
  max-width: 410px;
  margin-bottom: 25px;

  svg {
    margin-left: 12px;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    padding: 10px 0 10px 25px;
    font-size: 20px;
    opacity: unset;
    font-weight: 100 !important;

    transition: all 0.2s ease-in-out;
    border-left: 10px solid transparent;

    svg {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover {
      border-left: 10px solid ${({ theme }) => theme.colors.yellow};

      svg {
        opacity: 1;
      }
    }
  }
`;

const Message = styled.span`
  display: none;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 20px;
    align-items: center;
  }
`;

const MenuBackDrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => transparentize(0.05, theme.colors.red)};
  opacity: ${({ visible }) => (visible ? '1' : '0')};

  transition: opacity 0.2s ease-in-out;
  z-index: 100;
`;

const MobileScreenMenu = styled.div`
  display: flex;

  position: fixed;
  top: 150px;
  left: 20px;

  flex-direction: column;

  width: calc(100vw - 40px);
  height: calc(100vh - 150px);
  overflow-y: auto;
  padding-bottom: 60px;

  span {
    font-weight: 100;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    display: none;
  }
`;

const MobileSubMenu = styled.div`
  display: block;
  margin-top: 20px;
  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    display: none;
  }
`;

const LargeScreenMenu = styled.div`
  display: none;
  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    display: block;
  }
`;

const SubMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxl}) {
    gap: 20px;
  }
`;

const SubMenu = (props: {
  title: string;
  subMenu: LevelMenuItem[];
  currentSubMenu?: string;
  onClick: (submenu?: string) => void;
}) => {
  const { title, subMenu, currentSubMenu, onClick } = props;

  const open = title === currentSubMenu;

  return (
    <SubMenuContainer>
      <SubMenuButton
        open={open}
        onClick={() => onClick(open ? undefined : title)}
      >
        {title} {open ? <CloseIcon /> : <ChevronDown />}
      </SubMenuButton>

      <MobileSubMenu>
        {open && (
          <MenuItemsContainer>
            {subMenu.map((item) => {
              if (!item.link) return null;

              return (
                <NavLink to={item.link._url} withActiveState>
                  <FullWidthLink to={item.link._url}>
                    {item.title} <ChevronRight />
                  </FullWidthLink>
                </NavLink>
              );
            })}
          </MenuItemsContainer>
        )}
      </MobileSubMenu>
    </SubMenuContainer>
  );
};

const LevelMenu = (props: LevelMenuProps) => {
  const { menu, open, setOpen, setCurrentSubMenu, currentSubMenu } = props;

  const navigate = useNavigate();
  const navigationRoot = document.getElementById('navigation');
  if (navigationRoot === null) return null;

  const subMenus = menu.filter(
    (item) => item.subMenu && item.subMenu.length > 0,
  );

  const buttonLinks = menu.filter((item) => item.showAsButton);

  const links = menu.filter(
    (item) =>
      (item.subMenu === undefined || item.subMenu.length === 0) &&
      !item.showAsButton,
  );

  const toggleMobileMenu = () => {
    if (open) setCurrentSubMenu(undefined);
    setOpen(!open);
  };

  const menuBackDropVisible = currentSubMenu !== undefined || open;

  return (
    <div style={{ position: 'relative' }}>
      {menuBackDropVisible &&
        createPortal(
          <MenuBackDrop visible={menuBackDropVisible}>
            <LargeScreenMenu>
              <MenuItemsContainer>
                {subMenus
                  .find((s) => s.title === currentSubMenu)
                  ?.subMenu?.map((item) => {
                    if (!item.link) return null;

                    return (
                      <NavLink to={item.link._url} withActiveState>
                        <FullWidthLink to={item.link._url}>
                          {item.title} <ChevronRight />
                        </FullWidthLink>
                      </NavLink>
                    );
                  })}
              </MenuItemsContainer>
            </LargeScreenMenu>
          </MenuBackDrop>,
          navigationRoot,
        )}

      <MenuCloseMobileContainer onClick={toggleMobileMenu}>
        {!open && <MenuMobile />}
        {open && <MenuCloseMobile />}
      </MenuCloseMobileContainer>

      {open && (
        <MobileScreenMenu>
          {subMenus.map((item) => {
            return (
              <SubMenu
                title={item.title}
                subMenu={item.subMenu || []}
                currentSubMenu={currentSubMenu}
                onClick={setCurrentSubMenu}
              />
            );
          })}

          {buttonLinks.map((item) => {
            if (!item.link) return null;

            return (
              <NavLink to={item.link._url}>
                <SubMenuButton
                  style={{ display: 'inline-block' }}
                  onClick={() => item.link && navigate(item.link._url)}
                  open={false}
                  showAsButton
                >
                  {item.title}
                </SubMenuButton>
              </NavLink>
            );
          })}

          {links.map((item) => {
            if (!item.link) return null;

            return (
              <NavLink to={item.link._url} style={{ marginTop: '20px' }}>
                <MenuLink to={item.link._url}>{item.title}</MenuLink>
              </NavLink>
            );
          })}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              margin: '30px 0 0 30px',
            }}
          >
            <Spacer />
            <span>
              Your single point of contact:
              <div>
                <b>+45 53 53 84 23</b>
              </div>
            </span>
          </div>
        </MobileScreenMenu>
      )}

      <LargeScreenMenu>
        <Message>
          <Spacer />
          Your single point of contact: <b>+45 53 53 84 23</b>
          <Spacer />
        </Message>

        <LinkContainer>
          {menu.map((item) => {
            if (item.subMenu && item.subMenu.length > 0 && !item.showAsButton)
              return (
                <SubMenu
                  title={item.title}
                  subMenu={item.subMenu}
                  currentSubMenu={currentSubMenu}
                  onClick={setCurrentSubMenu}
                />
              );

            if (!item.link) return null;

            return (
              <NavLink to={item.link._url}>
                {item.showAsButton ? (
                  <SubMenuButton
                    onClick={() => item.link && navigate(item.link._url)}
                    open={false}
                    showAsButton
                  >
                    {item.title}
                  </SubMenuButton>
                ) : (
                  <BodyLink to={item.link._url}>{item.title}</BodyLink>
                )}
              </NavLink>
            );
          })}
        </LinkContainer>
      </LargeScreenMenu>
    </div>
  );
};

export default LevelMenu;
