import React from 'react';
import { ContentItem } from '../../types';

export type PlanYourFlightProps = ContentItem & {
  source: string;
};

const PlanYourFlight = (props: PlanYourFlightProps) => {
  const { source } = props;
  return (
    <div className="iframe-container">
      <iframe
        title={'Plan your flight'}
        style={{ width: '100%', height: '100vh', border: 'none' }}
        src={
          'https://app.flymoove.com/search?company=Heliois&iframe=true&langue=en'
        }
        scrolling="yes"
      >
        This browser does not support Iframe
      </iframe>
    </div>
  );
};

export default PlanYourFlight;
