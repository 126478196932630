/* eslint-disable no-restricted-globals */
import React, { FunctionComponent, useEffect, useState } from 'react';
import axios from 'axios';
import ScrollToTop from './components/scroll-top.';
import { ContentPage, ContentResponse, HomePageV2 } from './types';
import View from './pages/view';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HeaderLevelMenu from './components/header/header-level-menu';
import ImageWithContent, {
  ImageWithContentProps,
} from './components/content/image-with-content';
import FooterV2 from './components/footer-v2';

const pushState = history.pushState;
const App: FunctionComponent = () => {
  const [home, setHome] = useState<HomePageV2 | undefined>();
  const [pages, setPages] = useState<ContentPage[]>([]);

  useEffect(() => {
    axios
      .get<ContentResponse<ContentPage>>('https://cdn.umbraco.io/content', {
        headers: {
          'Accept-Language': 'en-US',
          'umb-project-alias': 'heliois',
        },
      })
      .then((result) => {
        const { data } = result;
        const root = data._embedded.content.find(
          ({ contentTypeAlias }) => contentTypeAlias === 'homeV2',
        );

        if (root) {
          setHome(root as HomePageV2);
        }
        console.log(data._embedded.content);
        setPages(data._embedded.content);
      });
  }, []);

  useEffect(() => {
    history.pushState = function (event, arg, target) {
      // @ts-ignore
      pushState.apply(history, arguments);
      window.scrollTo(0, 0);

      // @ts-ignore
      gtag('config', 'G-SVCL13TV0W', {
        page_path: target,
      });
    };

    return function () {
      history.pushState = pushState;
    };
  }, []);

  const location = useLocation();

  if (!home) return null;

  const page = pages.find(({ _url }) => _url === location.pathname) || home;

  const challengeUs = home.pageContent?.find(
    ({ contentTypeAlias }) => contentTypeAlias === 'challengeUs',
  ) as ImageWithContentProps;

  return (
    <ScrollToTop>
      <HeaderLevelMenu {...home} />
      {page && (
        <>
          <Helmet>
            <title>{page.title}</title>
          </Helmet>

          <View
            pageContent={page.pageContent?.filter(
              ({ contentTypeAlias }) => contentTypeAlias !== 'challengeUs',
            )}
          />
        </>
      )}

      {challengeUs && <ImageWithContent {...challengeUs} />}

      <FooterV2 {...home} />
    </ScrollToTop>
  );
};

export default App;
