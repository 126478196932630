import React, { useRef, useState } from 'react';
import { ButtonCardIcons, mapIcon } from '../button/button-card';
import { ReactComponent as Arrow } from './arrow.svg';
import styled, { useTheme } from 'styled-components';
import _ from 'lodash';
import { gsap } from 'gsap';
import { ContentItem } from '../../types';
import { useWindowSize } from '@uidotdev/usehooks';

const Container = styled.a`
  position: relative;
  width: 47%;
  height: 92px;
  cursor: pointer;

  @media all and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    width: 234px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  color: white;
  height: 100%;
  position: relative;
  z-index: 10;

  span {
    font-size: 12px;
    line-height: 18px;
  }

  svg {
    min-width: 45px;
    min-height: 45px;
    max-height: 45px;
    max-width: 45px;
  }

  @media all and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    span {
      font-size: 14px;
    }

    svg {
      min-width: 72px;
      min-height: 72px;
      max-height: 72px;
      max-width: 72px;
    }
  }
`;

const Backdrop = styled.div`
  background-color: ${({ theme }) => theme.colors.blue05};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  z-index: 1;
  opacity: 1;

  @media all and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    opacity: 0;
    background-color: ${({ theme }) => theme.colors.teal10};
  }
`;

const ArrowContainer = styled.div`
  position: absolute;
  bottom: 5px;
  right: 10px;
  opacity: 1;
  z-index: 10;

  @media all and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    opacity: 0;
    bottom: 5px;
    right: 30px;
  }
`;

export type SplashLink = {
  icon: ButtonCardIcons;
  text: string;
  link: ContentItem;
};

export const SplashCard = (props: SplashLink) => {
  const { icon, text, link } = props;
  const tl = useRef(gsap.timeline());
  const { width } = useWindowSize();
  const {
    breakpoints: { xxl },
  } = useTheme();
  const [id] = useState<string>(_.uniqueId());
  const Icon = mapIcon(icon);

  const onMouseOver = (id: string) => {
    if (!width || width < parseInt(xxl)) return _.noop;

    return function () {
      tl.current.to(`#button-card-background-${id}`, {
        autoAlpha: 0.15,
        duration: 0.15,
      });
      tl.current.to(`#arrow-container-${id}`, { autoAlpha: 1, x: 20 }, '-=0.2');
    };
  };

  const onMouseOut = (id: string) => {
    if (!width || width < parseInt(xxl)) return _.noop;

    return function () {
      tl.current.to(`#arrow-container-${id}`, {
        autoAlpha: 0,
        x: 0,
        duration: 0.05,
      });
      tl.current.to(
        `#button-card-background-${id}`,
        { autoAlpha: 0 },
        '-=0.05',
      );
    };
  };

  return (
    <Container
      id={`splash-link-card`}
      onMouseEnter={onMouseOver(id)}
      onMouseLeave={onMouseOut(id)}
      href={link._url}
    >
      <Backdrop id={`button-card-background-${id}`} />

      <Content>
        {Icon && <Icon />}
        <span>{text}</span>
      </Content>

      <ArrowContainer id={`arrow-container-${id}`}>
        <Arrow />
      </ArrowContainer>
    </Container>
  );
};
