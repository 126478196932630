import React, { useEffect, useRef, useState } from 'react';
import { ContentItem, MediaItem } from '../../types';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import _ from 'lodash';

export type ImageWithDescriptionProps = ContentItem & {
  image: MediaItem;
  description: string;
};

export type GoalsTheme = 'White' | 'Blue';

export type GoalsProps = ContentItem & {
  headline: string;
  goalsDescription: string;
  goals: ImageWithDescriptionProps[];
  goalsTheme?: GoalsTheme;
};

const Container = styled.div<{ goalsTheme: GoalsTheme }>`
  background-color: ${({ theme, goalsTheme }) =>
    goalsTheme === 'Blue' ? theme.colors.blue30 : 'white'};
  color: ${({ goalsTheme }) => (goalsTheme === 'Blue' ? 'white' : 'black')};
  padding: 80px 0;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }
`;

const ImageWithDescription = (props: ImageWithDescriptionProps) => {
  const { image, description } = props;

  return (
    <div
      style={{ display: 'flex', gap: '20px' }}
      className={'image-with-description'}
    >
      <div>
        {image.mediaTypeAlias === 'Image' && <img src={image._url} alt={''} />}
        {image.mediaTypeAlias === 'umbracoMediaVectorGraphics' && (
          <img
            src={image._url}
            alt={''}
            style={{ width: '100px', height: '100px' }}
          />
        )}
      </div>

      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

const Goals = (props: GoalsProps) => {
  const { headline, goalsDescription, goals, goalsTheme = 'Blue' } = props;

  const [_id] = useState(_.uniqueId('goals'));
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const q = gsap.utils.selector(containerRef);
    const tl = gsap.timeline();

    tl.from(q('#left-column'), { autoAlpha: 0, duration: 1 });
    tl.from(q('#right-column > *'), { autoAlpha: 0, x: 20, stagger: 0.2 });

    ScrollTrigger.create({
      trigger: `#${_id}`,
      animation: tl,
      start: 'top 80%',
      end: 'bottom top',
    });
  }, [_id]);

  return (
    <Container goalsTheme={goalsTheme}>
      <Grid id={_id} className={'helio-container'} ref={containerRef}>
        <div id={'left-column'}>
          <h2>{headline}</h2>
          <div dangerouslySetInnerHTML={{ __html: goalsDescription }} />
        </div>
        <RightColumn id={'right-column'}>
          {goals.map((goal) => (
            <ImageWithDescription {...goal} key={goal._id} />
          ))}
        </RightColumn>
      </Grid>
    </Container>
  );
};

export default Goals;
