import React from 'react';

function MenuMobile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
    >
      <g data-name="Group 79" transform="translate(-259 -74)">
        <circle
          cx="22"
          cy="22"
          r="22"
          fill="#d84632"
          data-name="Ellipse 3"
          transform="translate(259 74)"
        ></circle>
        <g fill="#fff" data-name="Group 78" transform="translate(271.746 86)">
          <path d="M0 0H18.507V3.291H0z" data-name="Rectangle 6"></path>
          <path
            d="M0 0H18.507V3.291H0z"
            data-name="Rectangle 7"
            transform="translate(0 16.709)"
          ></path>
          <path
            d="M0 0H18.507V3.291H0z"
            data-name="Rectangle 8"
            transform="translate(0 8.355)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default MenuMobile;
