import React from 'react';

function AppIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      viewBox="0 0 90 90"
    >
      <defs>
        <clipPath id="clip-path">
          <path fill="none" d="M0 0H90V90H0z" data-name="Rectangle 15"></path>
        </clipPath>
      </defs>
      <g fill="#fff" data-name="Group 25">
        <path
          d="M45 0a45 45 0 1045 45A45.052 45.052 0 0045 0m0 84.3A39.3 39.3 0 1184.3 45 39.347 39.347 0 0145 84.3"
          data-name="Path 54"
        ></path>
        <path
          d="M64.253 25.884H50.545a7.479 7.479 0 00-7.47 7.471v38.192a7.479 7.479 0 007.47 7.471h13.708a7.479 7.479 0 007.47-7.471V33.355a7.479 7.479 0 00-7.47-7.471m-13.708 3.561h13.708a3.914 3.914 0 013.909 3.91v31.564H46.636V33.355a3.914 3.914 0 013.909-3.91m13.708 46.011H50.545a3.914 3.914 0 01-3.909-3.91V68.48h21.526v3.067a3.914 3.914 0 01-3.909 3.91"
          data-name="Path 55"
          transform="translate(-12.399 -7.451)"
        ></path>
        <path
          d="M62.14 87.038a2.593 2.593 0 102.593 2.593 2.593 2.593 0 00-2.593-2.593"
          data-name="Path 56"
          transform="translate(-17.14 -25.053)"
        ></path>
        <path
          d="M52.825 50.846c-.018 1.711 3.208 3.3 7.2 3.539.239.014.466.011.7.016q.447.419.882.867a15.725 15.725 0 01-3.933.316 14.806 14.806 0 007.335 1.167 12.448 12.448 0 00-1.886-2.488 11.123 11.123 0 001.477-.293 14.3 14.3 0 01-1.612.143 21.681 21.681 0 00-8.1-4.818 5.407 5.407 0 012.011-.981 8.6 8.6 0 00-2.605.775 28.828 28.828 0 00-3.284-.88 26.781 26.781 0 012.682 1.248 1.813 1.813 0 00-.863 1.389m1.371-.292a1.417 1.417 0 01.207-.709 32.552 32.552 0 015.858 4.137c-3.451-.446-6.08-1.884-6.065-3.428"
          data-name="Path 57"
          transform="translate(-14.682 -13.877)"
        ></path>
        <path
          d="M61.7 48.968c4 .259 7.219 1.856 7.2 3.566-.012.97-1.065 1.768-2.7 2.23 2.4-.35 4.059-1.3 4.074-2.517.021-1.711-3.2-3.307-7.2-3.566a13.946 13.946 0 00-4.571.4 15.462 15.462 0 013.2-.111"
          data-name="Path 58"
          transform="translate(-16.838 -14.002)"
        ></path>
      </g>
    </svg>
  );
}

export default AppIcon;
