import styled from 'styled-components';
import { transparentize } from 'polished';

export type SplashType = 'full' | 'medium';

export type OnPageLink = { text: string; anchor: string };

export const Container = styled.div<{ type: SplashType }>`
  height: ${({ type }) => (type === 'full' ? '75vh' : '60vh')};
  background-color: black;
  position: relative;

  color: white;
  padding: 30px 0;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    height: ${({ type }) => (type === 'full' ? '100vh' : '700px')};
  }
`;

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  z-index: 1;
`;

export const VideoContainer = styled(Backdrop)`
  z-index: 10;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Content = styled.div<{
  type: SplashType;
  hasSplashLinks?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  padding-bottom: 0;
  transform-origin: left center;

  h1 {
    margin-top: 10px;
  }

  h4 {
    font-size: 25px;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    width: 70%;
    padding-bottom: ${({ hasSplashLinks }) => (hasSplashLinks ? '120px' : '0')};
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxl}) {
    width: 68%;
    padding-bottom: 0;
    h4 {
      font-size: 44px;
    }
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxxl}) {
    width: 60%;
    padding-bottom: 0;
    transform: unset;

    h1 {
      font-size: 80px;
      line-height: 84px;
      margin-top: 12px;
    }

    h4 {
      font-size: 48px;
    }
  }
`;

export const MainContent = styled.div`
  padding: 30px 0 0;
`;

export const OnPageLinkContainer = styled.div<{ type: SplashType }>`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  text-align: center;
  gap: 16px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    padding-bottom: ${({ type }) => (type === 'full' ? '20px' : '40px')};
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxl}) {
    padding-bottom: ${({ type }) => (type === 'full' ? '130px' : '40px')};
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxxl}) {
    padding-bottom: ${({ type }) => (type === 'full' ? '200px' : '40px')};
  }
`;

export const LinkItem = styled.a`
  border-radius: 25px;
  border-color: rgba(225, 225, 225, 40%);

  background-color: ${({ theme }) => transparentize(0.4, theme.colors.teal30)};
  padding: 4px 15px;
  margin: 10px 0 0 0;
  transition: all 0.3s ease-in-out;

  @media all and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.sm}) {
    margin: 0;
    padding: 2px 6px;
  }

  @media (hover) {
    &:hover {
      background-color: white;
      border-color: white;
      color: black;
    }
  }

  font-size: 14px;
  color: black;
  text-decoration: none;
  white-space: nowrap;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxl}) {
    font-size: 18px;
  }
`;

export const DesktopContent = styled.div`
  display: none;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    display: block;
  }
`;

export const MobileContent = styled.div`
  background-color: ${({ theme: { colors } }) => colors.blue30};
  color: white;
  display: block;
  padding-bottom: 30px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    display: none;
  }
`;

export const MobileSplashLinks = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    background-color: ${({ theme: { colors } }) => colors.blue10};
  }
`;

export const SplashLinks = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 172px;
  z-index: 10;
  padding-top: 30px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    display: flex;
  }
`;

export const SplashLinkBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme: { colors } }) => colors.blue40};
  opacity: 0.3;
  z-index: 11;
`;

export const MobileSplashLinksContainer = styled.div`
  background-color: #08141b66;
  padding: 20px 0;
`;

export const SplashLinkContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  position: relative;
  z-index: 12;
`;
