import React from 'react';

function CircleArrowYellow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="51"
      viewBox="0 0 51 51"
      className={'circle-arrow'}
    >
      <g
        fill="none"
        strokeWidth="5"
        data-name="Group 36"
        transform="translate(-305 -2573)"
      >
        <path
          stroke="#fff"
          d="M327.108 2608.713l10.606-10.606-10.606-10.607"
          data-name="Path 62"
        ></path>
        <g
          stroke="#f6c436"
          data-name="Ellipse 2"
          transform="translate(305 2573)"
        >
          <circle cx="25.5" cy="25.5" r="25.5" stroke="none"></circle>
          <circle cx="25.5" cy="25.5" r="23"></circle>
        </g>
      </g>
    </svg>
  );
}

export default CircleArrowYellow;
