import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import Button, {
  ButtonLink,
  ExternalLink,
  YellowThemeExternalLink,
} from '../button/button';
import LinkedInIconBlack from '../icons/linked-in-icon-black';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import AppleIcon from '../icons/apple-icon';
import GooglePlayIcon from '../icons/google-play-icon';
import { ContentItem, MediaItem, ContentTheme } from '../../types';
import _ from 'lodash';
import { ButtonCardIcons, mapIcon } from '../button/button-card';
gsap.registerPlugin(ScrollTrigger);

type ImageAlignment = 'top' | 'bottom' | 'center';

export type ImageWithContentProps = ContentItem & {
  anchor?: string;
  topHeader?: string;
  header: string;
  componentContent: string;
  secondaryContent?: string;
  buttonType?: 'link' | 'video' | 'tel' | 'external';
  buttonText?: string;
  buttonTarget?: string;
  buttonLink?: ContentItem;

  image?: MediaItem;
  imageType?: 'background' | 'image';
  imageAlignment?: ImageAlignment;
  imagePrimaryText?: string;
  imageSubText?: string;
  imageSocialCaption?: string;
  facebookLink?: string;
  linkedInLink?: string;

  selectedTheme?: ContentTheme;
  appStoreText?: string;
  appStoreUrl?: string;
  googlePlayText?: string;
  googlePlayUrl?: string;
  children?: React.ReactNode;

  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  icon?: ButtonCardIcons;
};

const TopHeader = styled.h3<{ contentTheme: ContentTheme }>`
  color: ${({ theme: { colors }, contentTheme }) =>
    contentTheme === 'teal'
      ? 'white'
      : contentTheme === 'service-view'
        ? colors.teal
        : colors.yellow};
  text-transform: uppercase;
`;

const Container = styled.div<{ selectedTheme: ContentTheme }>`
  h2,
  h3 {
    margin: 0;
  }

  h2 {
    margin: 16px 0 20px;
  }

  padding: 20px 0 0;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    padding: unset;

    h2 {
      margin: 20px 0;
    }
  }

  color: ${({ selectedTheme }) =>
    selectedTheme === 'blue' ? 'white' : 'black'};
  background-color: ${({ selectedTheme, theme }) =>
    selectedTheme === 'yellow'
      ? theme.colors.yellow
      : selectedTheme === 'blue'
        ? theme.colors.blue30
        : selectedTheme === 'teal'
          ? theme.colors.teal30
          : 'white'};
  position: relative;

  a {
    color: ${({ selectedTheme }) =>
      selectedTheme === 'blue' ? 'white' : 'black'};
    font-weight: bold;
  }

  button,
  a:not(.linkedin-icon) {
    border-color: ${({ selectedTheme, theme }) =>
      selectedTheme === 'yellow' || selectedTheme === 'teal'
        ? 'white'
        : theme.colors.yellow};
    color: ${({ selectedTheme }) =>
      selectedTheme === 'blue' ? 'white' : 'black'};

    &:hover {
      background-color: ${({ selectedTheme, theme }) =>
        selectedTheme === 'teal' || selectedTheme === 'yellow'
          ? 'white'
          : theme.colors.yellow};
    }
  }

  ul {
    margin-bottom: 30px;

    li {
      padding-bottom: 0;
    }
  }

  max-width: 100vw;
  overflow-x: hidden;
`;

const LeftColumn = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;

  display: flex;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    grid-column: 1;
    grid-row: unset;
  }
`;

export const RightColumn = styled.div<{
  imageType: 'background' | 'image';
  imageAlignment: ImageAlignment;
}>`
  display: flex;
  height: 320px;
  width: 100%;
  max-width: ${({ imageType }) => (imageType === 'image' ? '900px' : 'unset')};

  background-position: ${({ imageType, imageAlignment }) =>
    imageType === 'background'
      ? `${imageAlignment} right`
      : `${imageAlignment} ${imageType === 'image' ? 'center' : 'right'}`};
  background-repeat: no-repeat;
  background-size: contain;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    position: absolute;
    right: 0;
    top: ${({ imageType, imageAlignment }) =>
      imageType === 'image' && imageAlignment === 'top' ? '120px' : `0px`};

    height: 100%;
    width: 50vw;
  }
`;

const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 90%;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    grid-column: 2;
    grid-row: unset;
  }
`;

const ImageContent = styled.div`
  position: absolute;
  bottom: 20px;
  left: 30px;

  display: none;
  flex-direction: column;
  padding: 20px;
  width: 50%;

  span {
    line-height: 20px;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    display: flex;
    padding: 0;
    width: unset;
    left: 0;
  }
`;

const MobileImageContent = styled(ImageContent)`
  display: none;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0;
  align-items: center;

  flex-direction: column;
  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    flex-direction: row;
  }
`;

const ImageSubText = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`;

const ImageSocialCaption = styled(ImageSubText)`
  font-weight: 800;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);

  z-index: 400;
`;

const VideoContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 60vh;
  background-color: black;
  z-index: 450;
  padding: 20px;

  iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Grid = styled.div<{
  noPaddingTop: boolean;
  noPaddingBottom: boolean;
  hasImageContent: boolean;
}>`
  padding: ${({ noPaddingTop }) => (noPaddingTop ? '0px' : '20px')} 0
    ${({ noPaddingBottom }) => (noPaddingBottom ? '0' : '20px')};
  display: flex;
  flex-direction: column;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    padding: ${({ noPaddingTop }) => (noPaddingTop ? '0px' : '80px')} 0
      ${({ noPaddingBottom, hasImageContent }) =>
        noPaddingBottom ? '0' : hasImageContent ? '160px' : '80px'};
  }
`;

const ImageWithContent: FunctionComponent<ImageWithContentProps> = (props) => {
  const [_id] = useState(_.uniqueId('image-with-content'));

  const {
    buttonLink,
    topHeader,
    header,
    componentContent,
    secondaryContent,
    buttonText,
    buttonTarget,
    image,
    imagePrimaryText,
    imageSubText,
    imageSocialCaption,
    linkedInLink,
    appStoreText,
    appStoreUrl,
    googlePlayText,
    googlePlayUrl,
    children,
    buttonType = 'link',
    selectedTheme = 'white',
    imageType = 'background',
    imageAlignment = 'top',
    noPaddingTop = false,
    noPaddingBottom = false,
    anchor,
    icon,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const q = gsap.utils.selector(containerRef);
    const tl = gsap.timeline();

    tl.from(q('#background-image'), { autoAlpha: 0, duration: 1 });
    tl.from(q('#content-container > *'), { autoAlpha: 0, x: 20, stagger: 0.2 });
    tl.from(
      q('#image-content > *'),
      { autoAlpha: 0, duration: 0.75, stagger: 0.25, x: 20 },
      '-=0.2',
    );

    ScrollTrigger.create({
      trigger: `#${_id}`,
      animation: tl,
      start: 'top 80%',
      end: 'bottom top',
    });
  }, [_id]);

  const modalRoot = document.getElementById('modal');
  const Icon = icon && mapIcon(icon);
  const hasImageContent = Boolean(
    imagePrimaryText || imageSubText || imageSocialCaption,
  );

  return (
    <Container id={_id} selectedTheme={selectedTheme} ref={containerRef}>
      {modalIsOpen &&
        modalRoot &&
        createPortal(
          <>
            <Overlay onClick={() => setModalIsOpen(false)} />
            <VideoContainer>
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${buttonTarget}?rel=0`}
                title="HeliOIS"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </VideoContainer>
          </>,
          modalRoot,
        )}

      <div className="helio-container">
        {anchor && <div id={anchor} />}
        <Grid
          noPaddingTop={noPaddingTop}
          noPaddingBottom={noPaddingBottom}
          hasImageContent={hasImageContent}
        >
          <LeftColumn>
            <div id={'content-container'} style={{ width: '100%' }}>
              {Icon && (
                <div style={{ marginBottom: '20px' }}>
                  <Icon />
                </div>
              )}
              <TopHeader contentTheme={selectedTheme}>{topHeader}</TopHeader>
              {header && <h2>{header}</h2>}

              <div dangerouslySetInnerHTML={{ __html: componentContent }} />

              {children}

              <div style={{ marginTop: '40px' }}>
                {buttonTarget && buttonType === 'video' && (
                  <Button
                    onClick={() => setModalIsOpen(true)}
                    darkBackground={
                      selectedTheme === 'yellow' || selectedTheme === 'blue'
                    }
                  >
                    {buttonText}
                  </Button>
                )}
                {buttonLink?._url && buttonType === 'link' && (
                  <ButtonLink
                    darkBackground={
                      selectedTheme === 'yellow' || selectedTheme === 'blue'
                    }
                    to={buttonLink._url}
                  >
                    {buttonText}
                  </ButtonLink>
                )}

                {buttonTarget &&
                  (buttonType === 'tel' || buttonType === 'external') && (
                    <>
                      {selectedTheme !== 'yellow' && (
                        <ExternalLink
                          darkBackground={selectedTheme === 'blue'}
                          href={buttonTarget}
                        >
                          {buttonText}
                        </ExternalLink>
                      )}
                      {selectedTheme === 'yellow' && (
                        <YellowThemeExternalLink
                          darkBackground
                          href={buttonTarget}
                        >
                          {buttonText}
                        </YellowThemeExternalLink>
                      )}
                    </>
                  )}
              </div>

              {secondaryContent && (
                <div
                  style={{ marginTop: '60px' }}
                  dangerouslySetInnerHTML={{ __html: secondaryContent }}
                />
              )}

              <ButtonContainer>
                {appStoreUrl && appStoreText && (
                  <ExternalLink
                    href={appStoreUrl}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    <AppleIcon /> {appStoreText}
                  </ExternalLink>
                )}
                {googlePlayUrl && googlePlayText && (
                  <ExternalLink
                    href={googlePlayUrl}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    <GooglePlayIcon /> {googlePlayText}
                  </ExternalLink>
                )}
              </ButtonContainer>
            </div>
          </LeftColumn>

          <ImageContent id={'image-content'}>
            <span>{imagePrimaryText}</span>
            <ImageSubText>{imageSubText}</ImageSubText>
            <ImageSocialCaption>{imageSocialCaption}</ImageSocialCaption>
            {linkedInLink && (
              <a
                href={linkedInLink}
                target={'_blank'}
                rel={'noreferrer'}
                className={'linkedin-icon'}
              >
                <LinkedInIconBlack />
              </a>
            )}
          </ImageContent>

          {image && (
            <>
              <MobileImageContent id={'image-content'}>
                <span>{imagePrimaryText}</span>
                <ImageSubText>{imageSubText}</ImageSubText>
                <ImageSocialCaption>{imageSocialCaption}</ImageSocialCaption>
                {linkedInLink && (
                  <a href={linkedInLink} target={'_blank'} rel={'noreferrer'}>
                    <LinkedInIconBlack />
                  </a>
                )}
              </MobileImageContent>

              {imageType === 'image' && (
                <ImageColumn id={'background-image'}>
                  <img src={image?._url} alt={''} />
                </ImageColumn>
              )}
            </>
          )}
        </Grid>
      </div>

      {imageType === 'background' && image?._url && (
        <RightColumn
          imageAlignment={imageAlignment}
          imageType={imageType}
          style={{ backgroundImage: `url("${image?._url}")` }}
          id={'background-image'}
        />
      )}
    </Container>
  );
};

export default ImageWithContent;
