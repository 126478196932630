import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { Link, useLocation } from 'react-router-dom';
import routes from '../../routes';
import LevelMenu, { LevelMenuItem } from './level-menu';

const HeaderPosition = styled.div<{ open: boolean }>`
  position: ${({ open }) => (open ? 'fixed' : 'absolute')};
  z-index: 300;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    position: ${({ open }) => (open ? 'fixed' : 'absolute')};
  }
`;

const SecondHeaderPosition = styled.div`
  position: fixed;
  z-index: 301;
  top: 10px;
  right: 18px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxl}) {
    width: 66%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    opacity: 1;
  }
`;

const MenuContainer = styled.div`
  color: white;
  display: flex;
  align-items: center;
  gap: 20px;

  svg {
    cursor: pointer;
  }
`;

const LogoImg = styled.img`
  padding-top: 20px;
  width: 60%;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    width: 60%;
    padding-top: 20px;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxxl}) {
    width: 100%;
    padding: 0;
  }
`;

export type HeaderProps = {
  menu: LevelMenuItem[];
};

const Header: FunctionComponent<HeaderProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [currentSubMenu, setCurrentSubMenu] = useState<string | undefined>();

  const location = useLocation();
  useEffect(() => {
    setCurrentSubMenu(undefined);
    setOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const q = gsap.utils.selector(containerRef);
    const tl = gsap.timeline();
    tl.from(q('.helio-container'), { autoAlpha: 0, duration: 0.5 });
  }, []);

  const menuBackDropVisible = currentSubMenu !== undefined || open;

  return (
    <>
      <HeaderPosition id={'header-container'} open={menuBackDropVisible}>
        <div className={'helio-container'}>
          <HeaderContainer>
            <Link to={routes.home}>
              <LogoImg src={'/logo.png'} alt={'logo'} />
            </Link>
          </HeaderContainer>

          <SecondHeaderPosition
            ref={containerRef}
            id={'second-header-container'}
          >
            <HeaderContainer>
              <MenuContainer>
                <LevelMenu
                  {...props}
                  open={open}
                  currentSubMenu={currentSubMenu}
                  setCurrentSubMenu={setCurrentSubMenu}
                  setOpen={setOpen}
                />
              </MenuContainer>
            </HeaderContainer>
          </SecondHeaderPosition>
        </div>
      </HeaderPosition>
    </>
  );
};

export default Header;
