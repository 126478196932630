import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import CircleArrowYellow from '../icons/circle-arrow-yellow';
import { gsap } from 'gsap';
import HelicopterIcon from '../icons/helicopter';
import PlaneIcon from '../icons/plane';
import AppIcon from '../icons/app';
import Siren from '../icons/siren';
import InspectionIcon from '../icons/inspection';
import AuditIcon from '../icons/audit';
import PPEIcon from '../icons/ppe';
import Drone from '../icons/drone';
import LightBulbIcon from '../icons/light-bulb';
import { ReactComponent as AviationServicesIcon } from '../icons/aviation-services.svg';

import { ReactComponent as FleetIcon } from '../icons/fleet.svg';
import { ReactComponent as GroundServicesIcon } from '../icons/ground-services.svg';
import { ReactComponent as TravelServicesIcon } from '../icons/travel-services.svg';
import { ReactComponent as PPEV2Icon } from '../icons/ppe-v2.svg';
import { ReactComponent as BookFlightIcon } from '../icons/book-flight.svg';

import _ from 'lodash';
import { Link } from 'react-router-dom';
import { ContentItem } from '../../types';

const Container = styled(Link)`
  color: white;
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-template-rows: 1fr 1fr 1fr;

  max-width: 410px;

  background-color: transparent;
  padding: 10px 20px;
  border-radius: 32px;
  position: relative;
  cursor: pointer;

  margin-bottom: 30px;
  height: 210px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxl}) {
    height: 260px;
    padding: 20px;
  }

  &:hover {
    color: white;
    text-decoration: none;
  }
`;

const Background = styled.div`
  background-color: ${({ theme: { colors } }) => colors.teal10};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 32px;
  z-index: 1;
  opacity: 0.15;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxl}) {
    opacity: 0;
  }
`;

const IconContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  display: flex;
  align-items: center;

  position: relative;
  z-index: 10;

  width: 70%;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    width: 80%;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxxl}) {
    width: 100%;
  }
`;

const HeaderContainer = styled.div`
  grid-column: 2;
  grid-row: 1;

  display: flex;
  align-items: center;

  h3 {
    margin: 0;
    font-size: 24px;
  }

  position: relative;
  z-index: 10;
`;

const ContentContainer = styled.div`
  grid-column: 2;
  grid-row: 2;

  p {
    margin: 0;
  }

  position: relative;
  z-index: 10;
  padding-top: 6px;
  top: -20px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    top: unset;
  }
`;

const ArrowContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;

  grid-row: 3;

  display: flex;
  justify-content: flex-end;

  top: -10px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    margin-right: 40px;
    top: unset;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxl}) {
    opacity: 0;
  }

  position: relative;
  z-index: 10;
`;

export type ButtonCardIcons =
  | 'Helicopter'
  | 'Plane'
  | 'App'
  | 'Siren'
  | 'Inspection'
  | 'Auditor'
  | 'PPE'
  | 'Light bulb'
  | 'Drone'
  | 'Fleet'
  | 'AviationServices'
  | 'GroundServices'
  | 'PPE2'
  | 'TravelServices'
  | 'Expertise'
  | 'BookFlight';

export type ButtonCardProps = {
  id: number;
  icon: ButtonCardIcons;
  headline: string;
  cardLink: ContentItem;
  children?: React.ReactNode;
};

const tl = gsap.timeline();

const onMouseOver = (id: string) => {
  return function () {
    tl.to(`#button-card-background-${id}`, { autoAlpha: 0.15, duration: 0.15 });
    tl.to(`#arrow-container-${id}`, { autoAlpha: 1, x: 20 }, '-=0.2');
  };
};

const onMouseOut = (id: string) => {
  return function () {
    tl.to(`#arrow-container-${id}`, { autoAlpha: 0, x: 0, duration: 0.15 });
    tl.to(`#button-card-background-${id}`, { autoAlpha: 0 }, '-=0.2');
  };
};

export const mapIcon = (icon: ButtonCardIcons) => {
  if (icon === 'Helicopter') return HelicopterIcon;
  if (icon === 'Plane') return PlaneIcon;
  if (icon === 'App') return AppIcon;
  if (icon === 'Siren') return Siren;
  if (icon === 'Inspection') return InspectionIcon;
  if (icon === 'PPE') return PPEIcon;
  if (icon === 'Drone') return Drone;
  if (icon === 'Auditor') return AuditIcon;
  if (icon === 'Fleet') return FleetIcon;
  if (icon === 'AviationServices') return AviationServicesIcon;
  if (icon === 'GroundServices') return GroundServicesIcon;
  if (icon === 'PPE2') return PPEV2Icon;
  if (icon === 'TravelServices') return TravelServicesIcon;
  if (icon === 'Expertise' || icon === 'Light bulb') return LightBulbIcon;
  if (icon === 'BookFlight') return BookFlightIcon;

  return null;
};

const ButtonCard: FunctionComponent<ButtonCardProps> = ({
  icon,
  cardLink,
  headline,
  children,
}) => {
  const [id] = useState<string>(_.uniqueId());
  const Icon = mapIcon(icon);

  return (
    <Container
      to={cardLink?._url || '/'}
      onMouseEnter={onMouseOver(id)}
      onMouseLeave={onMouseOut(id)}
      id={'button-card-container'}
    >
      <Background id={'button-card-background-' + id} />

      <IconContainer>{Icon && <Icon />}</IconContainer>

      <HeaderContainer>
        <h3>{headline}</h3>
      </HeaderContainer>

      <ContentContainer>
        <p>{children}</p>
      </ContentContainer>

      <ArrowContainer id={'arrow-container-' + id}>
        <CircleArrowYellow />
      </ArrowContainer>
    </Container>
  );
};

export default ButtonCard;
