import React from 'react';

function GooglePlayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37.681"
      height="41.8"
      viewBox="0 0 37.681 41.8"
    >
      <path
        d="M26.276 19.132L8.254 1.062l22.93 13.163-4.908 4.908zM3.551 0a3.174 3.174 0 00-1.772 2.883v36.035A3.174 3.174 0 003.551 41.8L24.5 20.9zm34.721 18.422l-4.81-2.785L28.1 20.9l5.365 5.267 4.908-2.785a3.276 3.276 0 00-.1-4.965zM8.254 40.747l22.93-13.163-4.908-4.908z"
        data-name="Icon awesome-google-play"
        transform="translate(-1.779)"
      ></path>
    </svg>
  );
}

export default GooglePlayIcon;
