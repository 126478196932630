import React, { FunctionComponent, useEffect, useRef } from 'react';
import { ButtonLink, ExternalLink } from '../button/button';
import { gsap } from 'gsap';
import { ContentItem, MediaItem } from '../../types';
import { ButtonCardIcons, mapIcon } from '../button/button-card';
import { SplashCard, SplashLink } from './splash-card';
import {
  Backdrop,
  Container,
  Content,
  DesktopContent,
  LinkItem,
  MainContent,
  MobileContent,
  MobileSplashLinks,
  MobileSplashLinksContainer,
  OnPageLink,
  OnPageLinkContainer,
  SplashLinkBackdrop,
  SplashLinkContainer,
  SplashLinks,
  SplashType,
  VideoContainer,
} from './splash.styles';

export type SplashProps = ContentItem & {
  icon?: ButtonCardIcons;
  image?: MediaItem;
  video?: string;
  subHeadline?: string;
  headline: string;
  splashContent?: string;
  headlineSuffix?: string;
  buttonType?: 'tel' | 'link';
  buttonText?: string;
  buttonUrl?: string;
  buttonLink?: ContentItem;
  type?: SplashType;
  onPageLinks?: OnPageLink[];
  splashLinks?: SplashLink[];
};

const Splash: FunctionComponent<SplashProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    image,
    video,
    buttonType = 'link',
    buttonText,
    buttonLink,
    buttonUrl,
    headline,
    subHeadline,
    splashContent,
    icon,
    type = 'full',
    onPageLinks,
    splashLinks,
    headlineSuffix,
  } = props;

  useEffect(() => {
    const q = gsap.utils.selector(containerRef);
    const tl = gsap.timeline();

    tl.from(q('.backdrop'), { autoAlpha: 0, duration: 1 });
    tl.from(
      q('#splash-content > *'),
      { autoAlpha: 0, duration: 0.5, x: 20, stagger: 0.3 },
      '-=0.2',
    );
    tl.from(
      q('#splash-on-page-links'),
      { autoAlpha: 0, duration: 0.2, x: 20, stagger: 0.3 },
      '-=0.2',
    );
    tl.from(
      q('#splash-link-backdrop'),
      { autoAlpha: 0, duration: 0.5 },
      '-=0.1',
    );

    tl.from(
      q('#splash-link-card'),
      { autoAlpha: 0, duration: 0.5, x: 20, stagger: 0.15 },
      '-=0.2',
    );
  }, []);

  const Icon = icon && mapIcon(icon);

  const navigateOnPage = (
    e: React.MouseEvent<HTMLAnchorElement>,
    link: { anchor: string },
  ) => {
    e.preventDefault();
    const element = document.getElementById(link.anchor);
    if (element) element.scrollIntoView({ behavior: 'smooth' });
  };

  const hasSplashLinks = splashLinks && splashLinks.length > 0;

  return (
    <>
      <Container ref={containerRef} type={type}>
        {image && (
          <Backdrop
            className={'backdrop'}
            style={{ backgroundImage: `url("${image?._url}")` }}
          />
        )}
        {video && (
          <VideoContainer className={'backdrop'}>
            <video src={video} autoPlay muted loop playsInline />
          </VideoContainer>
        )}

        <div
          className={'helio-container'}
          style={{
            position: 'relative',
            zIndex: '10',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Content type={type} hasSplashLinks={hasSplashLinks}>
            <div id={'splash-content'} style={{ width: '100%' }}>
              {Icon && <Icon />}
              {subHeadline && <h4>{subHeadline}</h4>}

              <div style={{ display: 'flex', gap: '10px' }}>
                <h1>{headline}</h1>
                {headlineSuffix && (
                  <h1 style={{ fontWeight: '400' }}>{headlineSuffix}</h1>
                )}
              </div>

              <DesktopContent>
                {splashContent && (
                  <MainContent
                    dangerouslySetInnerHTML={{ __html: splashContent }}
                  />
                )}

                {buttonText && (
                  <>
                    {buttonType === 'tel' && buttonUrl && (
                      <ExternalLink darkBackground href={buttonUrl}>
                        {buttonText}
                      </ExternalLink>
                    )}

                    {buttonType === 'link' && buttonLink?._url && (
                      <ButtonLink to={buttonLink?._url} darkBackground>
                        {buttonText}
                      </ButtonLink>
                    )}
                  </>
                )}
              </DesktopContent>
            </div>
          </Content>

          <DesktopContent>
            <OnPageLinkContainer id={'splash-on-page-links'} type={type}>
              {onPageLinks?.map((link, index) => (
                <LinkItem
                  key={index}
                  href={`#${link.anchor}`}
                  onClick={(e) => navigateOnPage(e, link)}
                >
                  {link.text}
                </LinkItem>
              ))}
            </OnPageLinkContainer>
          </DesktopContent>
        </div>

        {hasSplashLinks && (
          <SplashLinks>
            <SplashLinkBackdrop id={'splash-link-backdrop'} />
            <div className={'helio-container'}>
              <SplashLinkContainer>
                {splashLinks.map((link, index) => (
                  <SplashCard {...link} key={index} />
                ))}
              </SplashLinkContainer>
            </div>
          </SplashLinks>
        )}
      </Container>
      <MobileContent>
        <div className={'helio-container'}>
          {splashContent && splashContent.length > 0 && (
            <MainContent dangerouslySetInnerHTML={{ __html: splashContent }} />
          )}

          {buttonUrl && buttonText && (
            <>
              {buttonType === 'tel' && (
                <ExternalLink darkBackground href={buttonUrl}>
                  {buttonText}
                </ExternalLink>
              )}

              {buttonType === 'link' && (
                <ButtonLink to={buttonUrl} darkBackground>
                  {buttonText}
                </ButtonLink>
              )}
            </>
          )}

          {onPageLinks && onPageLinks.length > 0 && (
            <OnPageLinkContainer
              type={type}
              style={{ marginTop: '40px', marginBottom: '20px' }}
            >
              {onPageLinks?.map((link, index) => (
                <LinkItem
                  key={index}
                  href={`#${link.anchor}`}
                  onClick={(e) => navigateOnPage(e, link)}
                >
                  {link.text}
                </LinkItem>
              ))}
            </OnPageLinkContainer>
          )}
        </div>

        {splashLinks && splashLinks.length > 0 && (
          <MobileSplashLinksContainer>
            <div className={'helio-container'}>
              <MobileSplashLinks>
                {splashLinks.map((link, index) => (
                  <SplashCard {...link} key={index} />
                ))}
              </MobileSplashLinks>
            </div>
          </MobileSplashLinksContainer>
        )}
      </MobileContent>
    </>
  );
};

export default Splash;
