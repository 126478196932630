import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 400;
    box-sizing: border-box;
    line-height: 26px;

    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.lg}) {
      font-weight: 100;
    }

    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xl}) {
      font-size: 17px;
      line-height: 21px;
    }

    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xxxl}) {
      font-size: 20px;  
      line-height: 26px;
    }
  }
  
  body {
    margin: 0;
    width: 100vw;
    overflow-x: hidden;
  }
  
  b, strong {
    font-weight: 800;
  }

  h1, h2, h3, h4, h5, h6 {
      margin-bottom: 30px;
  }
  
  h1 {
    font-weight: 800;
    font-size: 45px;
    line-height: 50px;

    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xl}) {
      font-size: 78px;
      line-height: 74px;
    }

    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xxxl}) {
      font-size: 100px;
      line-height: 98px;
    }
    
  }
  
  h2 {
    font-size: 36px;
    line-height: 32px;
    font-weight: 800;

    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xl}) {
      font-size: 50px;
      line-height: 48px;
    }

    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xxxl}) {
      font-size: 60px;
      line-height: 58px;
    }
  }
  
  h3 {
    font-size: 21px;
    line-height: 20px;
    font-weight: 800;
      margin-bottom: 10px;

    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xl}) {
      font-size: 28px;
      line-height: 28px;
      margin-bottom: unset;
    }

    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xxxl}) {
      font-size: 36px;
      line-height: 36px;
    }
  }
  
  h4 {
    font-size: 21px;
    font-weight: 800;
    margin: 28px 0 6px;

    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xl}) {
      font-size: 24px;  
      margin: 28px 0 10px;
    }

    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xxxl}) {
      font-size: 28px;
      padding-top: 22px;
    }
  }
  
  .helio-container {
    position: relative;
    margin: 0 auto;
    width: 90%;
    overflow-x: hidden;

    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xl}) {
      width: 1188px;
    }

    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xxl}) {
      width: 1366px;
    }
    
    @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xxxl}) {
      width: 1640px;      
    }
    
  }
  
  ul {
    list-style: square;
    padding-left: 20px;
  }
  
  a {
      color: black;
      text-decoration: none;
      opacity: 0.85;
      
      &:hover {
        color: black;
        text-decoration: none;
        opacity: 1;
      }
  }
  
  input:focus, textarea:focus, select:focus{
    outline: none;
  }
  
  body > #root > .info-box:last-of-type {
    padding-bottom: 120px !important;
  }
  
  .end-quote-marks {
      font-style: italic;
      font-weight: bold;
      margin-bottom: -10px;
      display: inline-flex;
      font-size: 20px;
      line-height: 30px;
      
      position: relative;
      top: -4px;

      @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.lg}) {
          font-size: 14px;
          top: 0;
      }
      
      @media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.xxxl}) {
          font-size: 16px;
          top: -2px;
      }
  }
`;

export default GlobalStyles;
