import React from 'react';

function InspectionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      viewBox="0 0 90 90"
    >
      <defs>
        <clipPath id="clip-path">
          <path fill="none" d="M0 0H90V90H0z" data-name="Rectangle 40"></path>
        </clipPath>
      </defs>
      <g fill="#fff" data-name="Group 53">
        <path
          d="M45 0a45 45 0 1045 45A45.051 45.051 0 0045 0m0 84.3A39.3 39.3 0 1184.3 45 39.347 39.347 0 0145 84.3"
          data-name="Path 130"
        ></path>
        <path
          d="M72.549 68.8a2.751 2.751 0 00-1.443-.761l.676-.676-1.176-1.176a18.46 18.46 0 10-4.58 4.581L67.2 71.94l.676-.677a2.751 2.751 0 00.761 1.443l8.74 8.74a2.765 2.765 0 003.91-3.91zM45.4 65.658a14.212 14.212 0 1110.051 4.162A14.229 14.229 0 0145.4 65.658"
          data-name="Path 131"
          transform="translate(-10.643 -10.688)"
        ></path>
        <path
          d="M63.337 58.056h-6.153V52.6h-4.167v15.081h4.167v-5.457h6.152v5.457H67.5V52.6h-4.163z"
          data-name="Path 132"
          transform="translate(-15.261 -15.14)"
        ></path>
      </g>
    </svg>
  );
}

export default InspectionIcon;
