import React, { FunctionComponent } from 'react';
import { NavigationItem } from './menu';
import HeaderSpacer from './header-spacer';
import styled from 'styled-components';
import { Link, useMatch } from 'react-router-dom';

type LinkGroupProps = {
  gridColumn: string;
  links: NavigationItem[];
  toggle: () => void;
};

const LinkColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavLinkContainer = styled.span<{
  isActive: boolean;
  withActiveState?: boolean;
}>`
  a {
    font-weight: ${({ isActive }) => (isActive ? 'bold' : '300')};
    border-left: ${({ theme, withActiveState, isActive }) =>
      withActiveState
        ? `10px solid ${isActive ? theme.colors.yellow : 'transparent'}`
        : 'none'};
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    a {
      font-weight: 100;
    }
  }
`;

type LinkProps = {
  to: string;
  children: React.ReactNode;
  withActiveState?: boolean;
  style?: React.CSSProperties;
};

export const NavLink: FunctionComponent<LinkProps> = ({
  to,
  children,
  withActiveState,
  style,
}) => {
  const match = useMatch(to);

  return (
    <NavLinkContainer
      style={style}
      isActive={Boolean(match)}
      withActiveState={withActiveState}
    >
      {children}
    </NavLinkContainer>
  );
};

export const LinkHeader = styled(Link)`
  font-size: 28px;
  font-weight: 100;
  color: white;

  margin: 60px 0 30px;

  display: flex;
  align-items: flex-end;
  line-height: 32px;

  &:hover {
    color: white;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxl}) {
    margin: 30px 0 30px;
    height: 140px;
  }
`;

export const BodyLink = styled(LinkHeader)`
  font-size: 20px;
  margin: 0;
  height: unset;
  max-width: 260px;
  line-height: 20px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    max-width: 160px;
    line-height: 26px;
  }
`;

export const BodyLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
`;

const LinkGroup: FunctionComponent<LinkGroupProps> = ({
  gridColumn,
  toggle,
  links,
}) => {
  const headerLink = links[0];
  const bodyLinks = links.slice(1);

  return (
    <LinkColumn style={{ gridColumn }}>
      <NavLink to={headerLink?.url}>
        <LinkHeader to={headerLink?.url} onClick={() => toggle()}>
          {headerLink?.name}
        </LinkHeader>
      </NavLink>

      <HeaderSpacer />

      <BodyLinkContainer>
        {bodyLinks.map(({ url, name }) => (
          <NavLink to={url}>
            <BodyLink to={url} onClick={() => toggle()}>
              {name}
            </BodyLink>
          </NavLink>
        ))}
      </BodyLinkContainer>
    </LinkColumn>
  );
};

export default LinkGroup;
