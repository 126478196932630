import React from 'react';

function Siren() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      viewBox="0 0 90 90"
    >
      <defs>
        <clipPath id="clip-path">
          <path fill="none" d="M0 0H90V90H0z" data-name="Rectangle 21"></path>
        </clipPath>
      </defs>
      <g fill="#fff" data-name="Group 27">
        <path
          d="M45 0a45 45 0 1045 45A45.051 45.051 0 0045 0m0 84.3A39.3 39.3 0 1184.3 45 39.347 39.347 0 0145 84.3"
          data-name="Path 59"
        ></path>
        <path
          d="M0 0H3.561V10.101H0z"
          data-name="Rectangle 16"
          transform="translate(43.946 15.279)"
        ></path>
        <path
          d="M0 0H10.102V3.561H0z"
          data-name="Rectangle 17"
          transform="translate(18.557 40.668)"
        ></path>
        <path
          d="M0 0H10.102V3.561H0z"
          data-name="Rectangle 18"
          transform="translate(62.795 40.668)"
        ></path>
        <path
          d="M1.851.91h3.56v10.1h-3.56z"
          data-name="Path 116"
          transform="rotate(-45 42.024 -15.55)"
        ></path>
        <path
          d="M.91 1.851h10.1v3.56H.91z"
          data-name="Path 117"
          transform="rotate(-45 61.64 -51.662)"
        ></path>
        <path
          d="M69.527 54.173a11.413 11.413 0 00-22.826 0v11.453h-3.04v15.355h29.266V65.626h-3.4zm-19.265 0a7.852 7.852 0 0115.7 0v11.453h-15.7zM69.366 77.42H47.221v-8.233h22.145z"
          data-name="Path 60"
          transform="translate(-12.567 -12.308)"
        ></path>
        <path
          d="M55.6 56.929h3.561a2.907 2.907 0 012.906-2.906v-3.561a6.466 6.466 0 00-6.467 6.467"
          data-name="Path 61"
          transform="translate(-16.005 -14.525)"
        ></path>
      </g>
    </svg>
  );
}

export default Siren;
