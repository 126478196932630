import React from 'react';

function PPEIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      viewBox="0 0 90 90"
    >
      <defs>
        <clipPath id="clip-path">
          <path fill="none" d="M0 0H90V90H0z" data-name="Rectangle 42"></path>
        </clipPath>
      </defs>
      <g fill="#fff" data-name="Group 59">
        <path
          d="M45 0a45 45 0 1045 45A45.051 45.051 0 0045 0m0 84.3A39.3 39.3 0 1184.3 45 39.348 39.348 0 0145 84.3"
          data-name="Path 139"
        ></path>
        <path
          d="M75.778 36.566l-2.344-.709.259-.337-1.049-.537.262-1.026a3.677 3.677 0 001.358-3.557 3.983 3.983 0 00-3.725-3.217h-.064a4.034 4.034 0 00-3.726 3.217 3.678 3.678 0 001.359 3.557l.263 1.029-1.042.533.26.338-2.34.707L64.087 38l-.578 2.636-1.109-5.045-1.4-1.734L58.171 33l.313-.407-1.266-.648.316-1.236a4.437 4.437 0 001.639-4.291 4.8 4.8 0 00-4.493-3.878h-.077a4.869 4.869 0 00-4.494 3.881 4.438 4.438 0 001.639 4.291l.317 1.241-1.257.644.312.403-2.822.853-1.4 1.733-1.129 5.145-.6-2.731-1.162-1.437-2.344-.709.259-.337-1.05-.537.262-1.026a3.678 3.678 0 001.359-3.554 3.983 3.983 0 00-3.725-3.217H38.7a4.035 4.035 0 00-3.722 3.217 3.678 3.678 0 001.359 3.557l.263 1.029-1.042.533.26.338-2.339.707L32.316 38l-1.585 7.225.991 6.529.446.209.223 1.6 1.484 1.185.056-.532-.4 15.752.371.209-1.782 2.76 1.262.558.742-.209 3.042-1.986-.149-.976-.371-.209.594-.627 1.312-16.263V35.636h.381v17.587l1.312 16.263.594.627-.372.209-.148.976 3.043 1.995 1.211.209.793-.558-1.782-2.76.371-.209-.4-15.752.056.532 1.484-1.185.223-1.6.445-.209.177-1.164.241 1.591.538.252.268 1.934 1.79 1.429.068-.642-.486 19 .447.252-2.149 3.331 1.522.672.9-.252 3.672-2.406-.179-1.177-.447-.252.716-.756 1.582-19.619V32.736h.46v21.216l1.582 19.619.716.756-.448.252-.179 1.177 3.672 2.406 1.461.252.957-.672-2.149-3.331.447-.252-.486-19 .068.642 1.79-1.429.269-1.934.537-.252.221-1.452.155 1.026.445.209.223 1.6 1.485 1.185.056-.532-.4 15.752.371.209-1.781 2.76 1.262.558.742-.209 3.038-1.992-.149-.976-.371-.209.594-.627 1.311-16.263V35.636h.378v17.587l1.312 16.263.593.627-.371.209-.148.976 3.043 1.995 1.211.209.793-.558-1.781-2.76.371-.209-.4-15.752.056.532 1.485-1.185.223-1.6.445-.209.992-6.529L76.939 38zm-36.533-3.995a.51.51 0 00-.917 0c-.572.47-2.288.739-2.5-1.544h5.922c-.214 2.282-1.93 2.014-2.5 1.544m16.011-3.532a.615.615 0 00-1.106 0c-.69.567-2.76.89-3.019-1.862h7.144c-.259 2.753-2.329 2.429-3.019 1.862m15.76 3.532a.51.51 0 00-.917 0c-.572.47-2.288.739-2.5-1.544h5.922c-.214 2.282-1.931 2.014-2.5 1.544"
          data-name="Path 140"
          transform="translate(-8.845 -6.487)"
        ></path>
      </g>
    </svg>
  );
}

export default PPEIcon;
