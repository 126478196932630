import React from 'react';
import styled from 'styled-components';
import { ContentItem, MediaItem } from '../../types';

export type PlaneDetailsProps = ContentItem & {
  image: MediaItem;
  airCraftName: string;
  crew?: string;
  capacity?: string;
  maxTakeOffWeight?: string;
  maxSpeed?: string;
  range?: string;
  travelSpeed?: string;
  transportCapacity?: string;
  endurance?: string;
  hoistLoad?: string;
};

const Container = styled.div`
  background-color: white;
  position: relative;
`;

const ImageContainer = styled.div<{ backgroundImage: string }>`
  bottom: 20px;
  justify-content: center;
  display: flex;

  height: 115px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('${({ backgroundImage }) => backgroundImage}');
  background-color: ${({ theme }): string => theme.colors.teal30};
  position: relative;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    height: 220px;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxxl}) {
    height: 270px;
  }
`;

const DetailsTable = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.teal30};
`;

const TitleRow = styled.div`
  color: ${({ theme }): string => theme.colors.teal30};
  font-weight: bold;
  flex: 1;
  padding: 4px 0;

  @media all and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.sm}) {
    font-size: 10px;
    line-height: 20px;
  }
`;

const DetailRow = styled.div`
  color: black;
  font-weight: lighter;
  flex: 1;
  padding: 4px 0;

  @media all and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.sm}) {
    font-size: 10px;
    line-height: 20px;
  }
`;

const Name = styled.h3`
  display: none;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    display: block;
    font-weight: 900;
    border-bottom: 1px solid ${({ theme }): string => theme.colors.teal30};

    margin: 0;
    padding: 4px 8px;
    color: white;
    background-color: ${({ theme }) => theme.colors.blue20};

    font-size: 28px;

    position: absolute;
    top: 0;
    left: 0;
  }
`;

const MobileName = styled.h3`
  display: inline-block;
  padding: 4px 8px;
  color: white;
  background-color: ${({ theme }) => theme.colors.blue20};
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 22px;
  margin: 0;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    display: none;
  }
`;

const PlaneDetails = (props: PlaneDetailsProps) => {
  const {
    image,
    airCraftName,
    crew,
    capacity,
    maxSpeed,
    maxTakeOffWeight,
    range,
    hoistLoad,
    transportCapacity,
    endurance,
    travelSpeed,
  } = props;

  return (
    <div className={'plane-details-container'}>
      <Container>
        {image && (
          <ImageContainer backgroundImage={image._url}>
            <Name>{airCraftName}</Name>
          </ImageContainer>
        )}
        <MobileName>{airCraftName}</MobileName>
        <DetailsTable>
          {crew && (
            <Row>
              <TitleRow>Crew:</TitleRow>
              <DetailRow>{crew}</DetailRow>
            </Row>
          )}
          {capacity && (
            <Row>
              <TitleRow>Capacity:</TitleRow>
              <DetailRow>{capacity}</DetailRow>
            </Row>
          )}
          {maxTakeOffWeight && (
            <Row>
              <TitleRow>Max Take Off Weight:</TitleRow>
              <DetailRow>{maxTakeOffWeight}</DetailRow>
            </Row>
          )}
          {maxSpeed && (
            <Row>
              <TitleRow>Max speed:</TitleRow>
              <DetailRow>{maxSpeed}</DetailRow>
            </Row>
          )}
          {range && (
            <Row>
              <TitleRow>Range:</TitleRow>
              <DetailRow>{range}</DetailRow>
            </Row>
          )}
          {travelSpeed && (
            <Row>
              <TitleRow>Travel Speed:</TitleRow>
              <DetailRow>{travelSpeed}</DetailRow>
            </Row>
          )}
          {transportCapacity && (
            <Row>
              <TitleRow>Transport Capacity:</TitleRow>
              <DetailRow>{transportCapacity}</DetailRow>
            </Row>
          )}
          {endurance && (
            <Row>
              <TitleRow>Endurance:</TitleRow>
              <DetailRow>{endurance}</DetailRow>
            </Row>
          )}
          {hoistLoad && (
            <Row>
              <TitleRow>Hoist Load:</TitleRow>
              <DetailRow>{hoistLoad}</DetailRow>
            </Row>
          )}
        </DetailsTable>
      </Container>
    </div>
  );
};

export default PlaneDetails;
