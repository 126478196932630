import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import LinkedInIconWhite from './icons/linked-in-icon-white';
import { Link } from 'react-router-dom';
import { ContentItem, MediaItem } from '../types';

export type FooterV2Props = {
  copyright: string;
  privacyLink: ContentItem;

  appTopHeader: string;
  appHeader: string;

  appContent: string;
  appImage: MediaItem;

  logo: MediaItem;
  address: string;
  cityAndZipCode: string;
  country: string;

  phoneLinkText: string;
  phoneLinkUrl: string;
  emailLinkText: string;
  emailLinkUrl: string;
  vat: string;

  socialHeader: string;
  facebookLink?: string;
  linkedInLink: string;

  firstColumnContent: string;
};

const FooterContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.blue30};
  padding: 100px 0;
  color: white;

  a {
    color: white;
    font-weight: bold;
  }
`;

const FooterGrid = styled.div`
  display: grid;

  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: unset;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    grid-template-rows: unset;
    grid-template-columns: 2fr 2fr 1fr;
  }
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  grid-row: 3;
  margin-top: 68px;

  & > * {
    color: white;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    grid-column: 1;
    grid-row: 2;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    grid-column: 1;
    grid-row: unset;
  }

  svg {
    fill: white;
    stroke: white;
    margin-bottom: 20px;
  }
`;

const SecondColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 2;
  width: 100%;

  img {
    width: 100%;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    grid-column: 2;
    grid-row: 1;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    justify-content: flex-end;
    grid-column: 2;
    grid-row: unset;
  }
`;

const ThirdColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: -50px;

  grid-row: 1;
  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    grid-column: 1;
    grid-row: 1;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    grid-column: 3;
    grid-row: unset;
    align-items: flex-end;
  }
`;

const ThirdColumnContent = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    fill: white;
    stroke: white;
  }

  img {
    width: 60%;
    margin-bottom: 20px;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.md}) {
    img {
      width: 30%;
    }
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    img {
      width: 90%;
    }
  }
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 60%;

  & > * {
    text-align: right;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxl}) {
    width: 50%;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxxl}) {
    width: 35%;
  }
`;

const ImageContainer = styled.div`
  width: 40%;
`;

const AppContentContainer = styled.div`
  display: flex;
  text-align: right;

  margin-top: 40px;

  & > *,
  a,
  strong {
    font-size: 14px !important;
    line-height: 22px;

    @media all and (min-width: ${({ theme: { breakpoints } }) =>
        breakpoints.xl}) {
      line-height: 20px;
    }
  }
`;

const AppHeader = styled.span`
  line-height: 32px;
  font-size: 28px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    line-height: 42px;
  }
`;

const FooterSubscript = styled.div`
  & > *,
  a {
    font-size: 14px !important;
    font-weight: lighter;
    color: ${({ theme }) => theme.colors.teal10};
    line-height: 20px;
  }

  display: flex;
  flex-direction: column;
`;

const ColumnContent = styled.div`
  display: flex;
  text-align: left;
  margin-top: 0;
  & > *,
  a,
  strong {
    font-size: 14px;
    line-height: 20px;
  
`;

const GoalImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  margin-bottom: 40px;
  img {
    flex: 1;
    max-width: calc(20% - 10px);
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  margin-top: 12px;
  gap: 12px;
`;

const FooterV2: FunctionComponent<FooterV2Props> = (props) => {
  const {
    copyright,
    privacyLink,
    appImage,
    appContent,
    appHeader,
    appTopHeader,
    logo,
    address,
    cityAndZipCode,
    country,
    phoneLinkText,
    phoneLinkUrl,
    emailLinkText,
    emailLinkUrl,
    vat,
    socialHeader,
    linkedInLink,
    firstColumnContent,
  } = props;

  return (
    <FooterContainer>
      <div className={'helio-container'} style={{ overflow: 'visible' }}>
        <FooterGrid>
          <FirstColumn>
            <ColumnContent
              dangerouslySetInnerHTML={{ __html: firstColumnContent }}
            />

            <GoalImageContainer>
              <img src="/E-WEB-Goal-03.png" alt={'Goal3'} />
              <img src="/E-WEB-Goal-09.png" alt={'Goal 9'} />
              <img src="/E-WEB-Goal-11.png" alt={'Goal 11'} />
              <img src="/E-WEB-Goal-13.png" alt={'Goal 13'} />
              <img src="/E-WEB-Goal-17.png" alt={'Goal17'} />
            </GoalImageContainer>

            <FooterSubscript>
              <span>{copyright}</span>
              {privacyLink && (
                <Link to={privacyLink._url}>{privacyLink.name}</Link>
              )}
            </FooterSubscript>
          </FirstColumn>

          <SecondColumn>
            <AppContainer>
              <span>
                <b>{appTopHeader}</b>
              </span>
              <AppHeader>{appHeader}</AppHeader>

              <FooterSubscript>
                <AppContentContainer
                  dangerouslySetInnerHTML={{ __html: appContent }}
                />
              </FooterSubscript>
            </AppContainer>

            <ImageContainer>
              <img src={appImage?._url} alt={'HeliOIS app'} />
            </ImageContainer>
          </SecondColumn>

          <ThirdColumn>
            <ThirdColumnContent>
              <img src={logo?._url} alt={'HeliOIS logo'} />
              <span>{address}</span>
              <span>{cityAndZipCode}</span>
              <span>{country}</span>

              <a href={`tel:${phoneLinkUrl}`} style={{ marginTop: '20px' }}>
                {phoneLinkText}
              </a>
              <a href={`mailto:${emailLinkUrl}`}>{emailLinkText}</a>
              <span>{vat}</span>

              <span
                style={{
                  fontSize: '14px',
                  marginTop: '20px',
                  fontWeight: 'bold',
                }}
              >
                {socialHeader}
              </span>
              <SocialLinks>
                <a href={linkedInLink} target={'_blank'} rel={'noreferrer'}>
                  <LinkedInIconWhite />
                </a>
              </SocialLinks>
            </ThirdColumnContent>
          </ThirdColumn>
        </FooterGrid>
      </div>
    </FooterContainer>
  );
};

export default FooterV2;
