const Routes = {
  home: '/',
  aviationServices: '/aviation-services',
  tailoredAviation: '/tailored-aviation',
  corporateTravel: '/corporate-travel',
  app: '/app',
  airAmbulance: '/air-ambulance',
  inspectionSafetyServices: '/inspection-safety-services',
  inspectionServices: '/inspection-services',
  auditSurvey: '/audit-survey',
  ppe: '/ppe',
  offshoreDroneExpertise: '/offshore-drone-expertise',
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  privacy: '/privacy',
};

export default Routes;
