import React, { useEffect, useRef, useState } from 'react';
import { ContentItem, MediaItem } from '../../types';
import styled from 'styled-components';
import { ReactComponent as HeliIcon } from './heli.svg';
import _ from 'lodash';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export type TravelTimeProps = ContentItem & {
  headline: string;
  helicopterTravelTimes: string[];
  mobilisationTime: string;
  location: MediaItem;
};

const Container = styled.div`
  background-color: ${({ theme: { colors } }) => colors.blue30};
  padding: 80px 0 0;
  position: relative;

  @media all and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 80px 0;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;

  @media all and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 35px;
  }
`;

const Mark = styled.span`
  color: ${({ theme }) => theme.colors.yellow};
  font-weight: bold;
  position: absolute;
  top: 15px;
  right: 15px;
`;

const Box = styled.div`
  background-color: white;
  padding: 60px;
  min-height: 534px;
  position: relative;
`;

const Image = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 50vh;

  @media all and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 50%;
    height: unset;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`;

const TravelTime = (props: TravelTimeProps) => {
  const { headline, helicopterTravelTimes, mobilisationTime } = props;

  const [_id] = useState(_.uniqueId('travel-time'));
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const q = gsap.utils.selector(containerRef);
    const tl = gsap.timeline();

    tl.from(q(`#${_id}-image`), { autoAlpha: 0, duration: 1 });
    tl.from(q(`#${_id}-times`), {
      autoAlpha: 0,
      x: 20,
      stagger: 0.2,
    });

    ScrollTrigger.create({
      trigger: `#${_id}`,
      animation: tl,
      start: 'top 80%',
      end: 'bottom top',
    });
  }, [_id]);

  return (
    <Container ref={containerRef} id={_id}>
      <div className={'helio-container'}>
        <Grid>
          <Box id={`${_id}-times`}>
            <Mark>{'////'}</Mark>

            <h3>{headline}</h3>
            <p>
              <b>Helicopter Travel Times:</b>
            </p>
            <>
              {helicopterTravelTimes.map((time, index) => {
                const [minutes, ...rest] = time.split(' ');
                return (
                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                      marginBottom: '10px',
                    }}
                    key={index}
                  >
                    <span>
                      <HeliIcon />
                    </span>{' '}
                    <span>
                      <b>{minutes}</b> {rest.join(' ')}
                    </span>
                  </div>
                );
              })}
            </>

            <div
              style={{ display: 'flex', gap: '20px', marginTop: '40px' }}
              dangerouslySetInnerHTML={{
                __html: mobilisationTime,
              }}
            />
          </Box>
        </Grid>
      </div>

      <Image src={props.location._url} id={`${_id}-image`} />
    </Container>
  );
};

export default TravelTime;
