import React from 'react';

function AppleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37.681"
      height="44.841"
      viewBox="0 0 37.681 44.841"
    >
      <path
        d="M31.785 25.946c-.02-3.674 1.642-6.447 5.005-8.489-1.882-2.693-4.725-4.175-8.479-4.465-3.554-.28-7.438 2.072-8.86 2.072-1.5 0-4.945-1.972-7.648-1.972C6.218 13.182.281 17.547.281 26.426a24.951 24.951 0 001.442 8.129C3 38.229 7.629 47.239 12.454 47.089c2.523-.06 4.3-1.792 7.588-1.792 3.183 0 4.835 1.792 7.648 1.792 4.865-.07 9.05-8.259 10.271-11.943-6.527-3.073-6.177-9.01-6.177-9.2zM26.119 9.508a9.429 9.429 0 002.4-7.258 10.615 10.615 0 00-6.8 3.494 9.577 9.577 0 00-2.563 7.2c2.613.2 5-1.141 6.958-3.434z"
        data-name="Icon awesome-apple"
        transform="translate(-.281 -2.25)"
      ></path>
    </svg>
  );
}

export default AppleIcon;
