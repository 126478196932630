import React from 'react';

function PlaneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      viewBox="0 0 90 90"
    >
      <defs>
        <clipPath id="clip-path">
          <path fill="none" d="M0 0H90V90H0z" data-name="Rectangle 13"></path>
        </clipPath>
      </defs>
      <g data-name="Group 54">
        <g fill="#fff" data-name="Group 21">
          <path
            d="M45 90a45 45 0 1145-45 45.051 45.051 0 01-45 45m0-84.3A39.3 39.3 0 1084.3 45 39.347 39.347 0 0045 5.7"
            data-name="Path 49"
          ></path>
          <path
            d="M59.927 79.274l3.184-3.184L61.1 49.913s9.33-10.094 11.29-12.054c1.921-1.921 2.846-5.775 1.985-6.823-.009-.009-.021-.018-.031-.026s-.017-.021-.026-.031c-1.048-.862-4.9.064-6.823 1.985-1.959 1.959-12.055 11.293-12.055 11.293l-26.177-2.014-3.184 3.183L47.127 53.9l-6.642 8.356-11.33.093-2.775 2.778 10.01 3.838 4.027 9.818 2.776-2.775-.1-11.139 8.356-6.642z"
            data-name="Path 50"
            transform="translate(-7.507 -8.824)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default PlaneIcon;
