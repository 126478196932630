import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { ContentItem } from '../../types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import _ from 'lodash';
gsap.registerPlugin(ScrollTrigger);

export type InfoBoxProps = ContentItem & {
  infoBoxHeader?: string;
  children?: React.ReactNode;
  infoBoxContent?: string;
  infoBoxSubHeader: string;
  infoBoxInnerContent: string;
};

const Container = styled.div`
  background-color: ${({ theme }) => transparentize(0.75, theme.colors.teal20)};
  padding: 20px;
  margin-bottom: 40px;

  ul {
    margin-bottom: 30px;

    li {
      padding-bottom: 8px;

      @media all and (min-width: ${({ theme: { breakpoints } }) =>
          breakpoints.xl}) {
        padding-bottom: 20px;
      }
    }
  }
`;

const Mark = styled.span`
  color: ${({ theme }) => theme.colors.red};
  font-weight: bold;
`;

const Header = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const InnerContainer = styled.div`
  width: 100%;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    width: 50%;
  }
`;

const InfoBox: FunctionComponent<InfoBoxProps> = ({
  infoBoxSubHeader,
  infoBoxInnerContent,
  infoBoxHeader,
  infoBoxContent,
  children,
}) => {
  const [_id] = useState(_.uniqueId('info-box'));
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const q = gsap.utils.selector(containerRef);
    const tl = gsap.timeline();

    tl.from(q('.info-box > *'), { autoAlpha: 0, x: 20, stagger: 0.2 });

    ScrollTrigger.create({
      trigger: `#${_id}`,
      animation: tl,
      start: 'top 80%',
      end: 'bottom top',
    });
  }, [_id]);

  return (
    <div className={'helio-container info-box'} id={_id} ref={containerRef}>
      <InnerContainer>
        {infoBoxHeader && <h4>{infoBoxHeader}</h4>}
        {infoBoxContent && (
          <div dangerouslySetInnerHTML={{ __html: infoBoxContent }} />
        )}
        <Container>
          <Header>
            <Mark>{'////'}</Mark> {infoBoxSubHeader}
          </Header>
          <div dangerouslySetInnerHTML={{ __html: infoBoxInnerContent }} />
          {children}
        </Container>
      </InnerContainer>
    </div>
  );
};

export default InfoBox;
