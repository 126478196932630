import React from 'react';

function LightBulbIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      data-name="Group 51"
      viewBox="0 0 90 90"
    >
      <g fill="#fff" data-name="Group 50">
        <path
          d="M45 0a45 45 0 1045 45A45.051 45.051 0 0045 0m0 84.3A39.3 39.3 0 1184.3 45 39.347 39.347 0 0145 84.3"
          data-name="Path 123"
        ></path>
        <path
          d="M71.612 50.633c-2.025-5.773-7.072-9.219-13.5-9.219H58.1c-6.429 0-11.477 3.446-13.5 9.22-2.084 5.941-.349 12.642 4.219 16.3A8.115 8.115 0 0151.8 71.86l.16 1.6h12.28l.161-1.6a8.119 8.119 0 012.988-4.931c4.568-3.654 6.3-10.356 4.218-16.3M65.17 64.15a12.221 12.221 0 00-3.978 5.75h-6.174a12.221 12.221 0 00-3.977-5.754c-3.407-2.725-4.675-7.8-3.083-12.338 1.525-4.345 5.221-6.837 10.142-6.837h.011c4.921 0 8.618 2.492 10.142 6.837 1.592 4.539.325 9.613-3.082 12.338"
          data-name="Path 124"
          transform="translate(-12.586 -11.921)"
        ></path>
        <path
          d="M0 0H11.768V2.003H0z"
          data-name="Rectangle 32"
          transform="translate(39.624 63.105)"
        ></path>
        <path
          d="M0 0H11.768V2.003H0z"
          data-name="Rectangle 33"
          transform="translate(39.624 66.907)"
        ></path>
        <path
          d="M62.66 101.5a2.9 2.9 0 003.074-2.7h-6.148a2.9 2.9 0 003.074 2.7"
          data-name="Path 125"
          transform="translate(-17.152 -28.439)"
        ></path>
        <path
          d="M55.017 58.43a5.039 5.039 0 015.033-5.03v-3.564a8.6 8.6 0 00-8.594 8.594z"
          data-name="Path 126"
          transform="translate(-14.811 -14.345)"
        ></path>
        <path
          d="M0 0H3.561V10.75H0z"
          data-name="Rectangle 34"
          transform="translate(43.472 13.927)"
        ></path>
        <path
          d="M0 0H10.749V3.561H0z"
          data-name="Rectangle 35"
          transform="translate(16.34 41.06)"
        ></path>
        <path
          d="M0 0H10.75V3.561H0z"
          data-name="Rectangle 36"
          transform="translate(63.416 41.06)"
        ></path>
        <path
          d="M1.963.929h3.56v10.749h-3.56z"
          data-name="Path 127"
          transform="rotate(-45 40.189 -13.766)"
        ></path>
        <path
          d="M.929 1.963h10.749v3.56H.929z"
          data-name="Path 128"
          transform="rotate(-45 61.203 -52.138)"
        ></path>
      </g>
    </svg>
  );
}

export default LightBulbIcon;
