import React, { FunctionComponent, useState } from 'react';
import InfoBox from '../content/info-box';
import TextField from '../input/text-field';
import styled from 'styled-components';
import Button from '../button/button';
import axios from 'axios';
import { ContentItem } from '../../types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
`;

const OuterContainer = styled.div`
  padding-bottom: 80px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    padding-bottom: 180px;
  }
`;

const InnerContainer = styled.div`
  width: 100%;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    width: 50%;
  }
`;

export type ContactFormProps = ContentItem & {
  namePlaceholder: string;
  emailPlaceholder: string;
  messagePlaceholder: string;
};

const ContactForm: FunctionComponent<ContactFormProps> = (props) => {
  const { namePlaceholder, emailPlaceholder, messagePlaceholder } = props;
  const [saving, setSaving] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const send = async () => {
    setSaving(true);

    try {
      await axios.post(
        'https://us-central1-heliois-f1e39.cloudfunctions.net/sendEmail',
        {
          name,
          email,
          message,
        },
        {
          headers: {
            'content-type': 'application/json',
            accept: 'application/json',
          },
        },
      );

      setSent(true);
    } catch (e) {
      console.log(e);
    } finally {
      setSaving(false);
    }
  };

  return (
    <OuterContainer className={'helio-container'}>
      <InnerContainer>
        <InfoBox
          {...props}
          infoBoxSubHeader={'E-mail us:'}
          infoBoxInnerContent={''}
        >
          <Container>
            {!sent && (
              <>
                <TextField
                  value={name}
                  onChange={setName}
                  placeholder={namePlaceholder}
                />
                <TextField
                  value={email}
                  onChange={setEmail}
                  placeholder={emailPlaceholder}
                />
                <TextField
                  value={message}
                  onChange={setMessage}
                  placeholder={messagePlaceholder}
                />

                <ButtonContainer>
                  {!saving && (
                    <Button onClick={send} withBackgroundColor>
                      Send
                    </Button>
                  )}
                  {saving && (
                    <span>
                      <i>Sending...</i>
                    </span>
                  )}
                </ButtonContainer>
              </>
            )}

            {sent && (
              <p style={{ marginTop: '20px' }}>
                <strong>We have received your message.</strong>
                <br />
                We will contact you as soon as possible.
              </p>
            )}
          </Container>
        </InfoBox>
      </InnerContainer>
    </OuterContainer>
  );
};

export default ContactForm;
