import React, { useEffect, useRef, useState } from 'react';
import { ContentItem, MediaItem, ContentTheme } from '../../types';
import styled from 'styled-components';
import { mapThemeToColor } from '../../styled.d';
import _ from 'lodash';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ReactComponent as HeliIcon } from '../travel-time/heli.svg';
import { ReactComponent as ShuttleIcon } from '../icons/shuttle.svg';
import { ReactComponent as AirplaneIcon } from '../icons/airplane.svg';

type ImageCaptionLayout = 'image-left' | 'image-right';

export type ImageCaptionProps = ContentItem & {
  anchor?: string;
  header: string;
  topHeader: string;
  componentContent: string;
  image?: MediaItem;
  layout: ImageCaptionLayout;
  theme?: ContentTheme;
  travelTimeHeadline?: string;
  helicopterTravelTimes?: string[];
  mobilisationTime?: string[];
  airportTime?: string[];
};

const TopHeader = styled.h3`
  color: white;
  text-transform: uppercase;
`;

const Container = styled.div<{ contentTheme: ContentTheme }>`
  h2,
  h3 {
    margin: 0;
  }
  h2 {
    margin: 20px 0;
  }

  color: black;
  background-color: ${({ theme, contentTheme }): string =>
    mapThemeToColor(contentTheme, theme)};
  position: relative;
`;

const TextColumn = styled.div`
  position: relative;
  z-index: 100;
  flex-direction: column;

  align-items: center;
  display: flex;
  padding: 20px;
  justify-content: flex-start;

  @media (min-width: ${({ theme }): string => theme.breakpoints.lg}) {
    padding: 80px 40px 80px 0;
  }
`;

const ImageColumn = styled.div<{
  backgroundImage: string;
  layout: ImageCaptionLayout;
}>`
  display: flex;

  width: 100%;
  height: 50vh;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('${({ backgroundImage }) => backgroundImage}');

  @media (min-width: ${({ theme }): string => theme.breakpoints.lg}) {
    width: 50vw;
    position: absolute;
    top: 0;

    left: ${({ layout }) => (layout === 'image-left' ? '0' : '50vw')};
    height: 100%;
    float: right;
  }
`;

const TravelTimeContainer = styled.div<{ layout: ImageCaptionLayout }>`
  width: 100%;
  padding: 40px;
  background-color: ${({ theme }): string => theme.colors.teal30};

  @media (min-width: ${({ theme }): string => theme.breakpoints.lg}) {
    position: relative;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
    float: right;
    padding: 50px 100px;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: ${({ theme }): string => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const dualFormatHeader = (topHeader: string) =>
  topHeader.replace(
    /\(.*\)/g,
    "<span style='font-weight: 400; text-transform: none; font-size: inherit'>$&</span>",
  );

const Times = ({ time }: { time: string }) => {
  const [minutes, ...rest] = time.split(' ');
  return (
    <div>
      <b>{minutes}</b> {rest.join(' ')}
    </div>
  );
};

const TravelTimeColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 40px;
`;

const ImageCaption = (props: ImageCaptionProps) => {
  const {
    header,
    componentContent,
    topHeader,
    image,
    layout,
    theme = 'white',
    anchor,
    travelTimeHeadline,
    helicopterTravelTimes,
    mobilisationTime,
    airportTime,
  } = props;

  const _topHeader = dualFormatHeader(topHeader);

  const [_id] = useState(_.uniqueId('image-caption'));
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const q = gsap.utils.selector(containerRef);
    const tl = gsap.timeline();

    tl.from(q(`#${_id}-image`), { autoAlpha: 0, duration: 1 });
    tl.from(q(`#${_id}-content-container > *`), {
      autoAlpha: 0,
      x: 20,
      stagger: 0.2,
    });

    ScrollTrigger.create({
      trigger: `#${_id}`,
      animation: tl,
      start: 'top 80%',
      end: 'bottom top',
    });
  }, [_id]);

  return (
    <Container contentTheme={theme} ref={containerRef} id={_id}>
      <div>
        {anchor && <div id={anchor} />}
        <>
          {!travelTimeHeadline && image?._url && (
            <ImageColumn
              id={`${_id}-image`}
              backgroundImage={image?._url}
              layout={layout}
            />
          )}

          <div className={'helio-container'}>
            <Grid>
              <TextColumn
                style={{ gridColumn: layout === 'image-left' ? '2' : '1' }}
              >
                <div id={`${_id}-content-container`} style={{ width: '100%' }}>
                  <TopHeader dangerouslySetInnerHTML={{ __html: _topHeader }} />
                  <h2>{header}</h2>
                  <div dangerouslySetInnerHTML={{ __html: componentContent }} />
                </div>
              </TextColumn>

              {travelTimeHeadline && (
                <TravelTimeContainer id={`${_id}-image`} layout={layout}>
                  <h4>{travelTimeHeadline}</h4>
                  <TravelTimeColumn>
                    {helicopterTravelTimes?.length && <HeliIcon />}

                    {helicopterTravelTimes?.map((time, index) => (
                      <Times key={index} time={time} />
                    ))}
                  </TravelTimeColumn>
                  <TravelTimeColumn>
                    {mobilisationTime?.length && <ShuttleIcon />}
                    {mobilisationTime?.map((time, index) => (
                      <Times key={index} time={time} />
                    ))}
                  </TravelTimeColumn>
                  <TravelTimeColumn>
                    {airportTime?.length && <AirplaneIcon />}
                    {airportTime?.map((time, index) => (
                      <Times key={index} time={time} />
                    ))}
                  </TravelTimeColumn>
                </TravelTimeContainer>
              )}
            </Grid>
          </div>

          {travelTimeHeadline && (
            <TravelTimeContainer
              style={{
                position: 'absolute',
                zIndex: '50',
                top: '0',
                left: '50%',
              }}
              id={`${_id}-image`}
              layout={layout}
            ></TravelTimeContainer>
          )}
        </>
      </div>
    </Container>
  );
};
export default ImageCaption;
