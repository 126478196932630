import React from 'react';

function Drone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      data-name="Group 70"
      viewBox="0 0 90 90"
    >
      <defs>
        <clipPath id="clip-path">
          <path fill="none" d="M0 0H90V90H0z" data-name="Rectangle 43"></path>
        </clipPath>
      </defs>
      <g data-name="Group 61">
        <g fill="#fff" data-name="Group 60">
          <path
            d="M45 0a45 45 0 1045 45A45.051 45.051 0 0045 0m0 84.3A39.3 39.3 0 1184.3 45 39.347 39.347 0 0145 84.3"
            data-name="Path 141"
          ></path>
          <path
            d="M57.618 51.483l3.939-3.926a10.188 10.188 0 10-2.485-2.549L56.67 47.4l-1.407-1.424h-3.409l-1.654 1.56-2.619-2.629a10.313 10.313 0 10-2.456 2.579l4.2 4.215v3.015l-4.39 4.375a10.245 10.245 0 102.51 2.525l3.244-3.233h5.3l3.181 3.193a10.253 10.253 0 102.521-2.515l-4.074-4.087zm1.827-12.209a8.109 8.109 0 113.646 6.756l5.717-5.7-2.513-2.522-5.669 5.651a8.035 8.035 0 01-1.18-4.187m-20.393 8.096a8.122 8.122 0 116.982-4.017l-5.567-5.586-2.522 2.515 5.662 5.68a8.048 8.048 0 01-4.555 1.408m8.1 20.077a8.12 8.12 0 11-3.753-6.818L37.9 66.1l2.513 2.522 5.489-5.47a8.035 8.035 0 011.243 4.289m20.391-8.1a8.121 8.121 0 11-6.83 3.769l5.533 5.551 2.522-2.513L63.23 60.6a8.039 8.039 0 014.309-1.254"
            data-name="Path 142"
            transform="translate(-8.296 -8.359)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Drone;
