import React from 'react';

function AuditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      viewBox="0 0 90 90"
    >
      <defs>
        <clipPath id="clip-path">
          <path fill="none" d="M0 0H90V90H0z" data-name="Rectangle 41"></path>
        </clipPath>
      </defs>
      <g data-name="Group 68">
        <g fill="#fff" data-name="Group 57">
          <path
            d="M45 0a45 45 0 1045 45A45.051 45.051 0 0045 0m0 84.3A39.3 39.3 0 1184.3 45 39.347 39.347 0 0145 84.3"
            data-name="Path 133"
          ></path>
          <path
            d="M72.549 68.8a2.751 2.751 0 00-1.443-.761l.676-.676-1.176-1.176a18.46 18.46 0 10-4.58 4.581L67.2 71.94l.676-.677a2.751 2.751 0 00.761 1.443l8.74 8.74a2.765 2.765 0 003.91-3.91zM45.4 65.658a14.212 14.212 0 1110.051 4.162A14.229 14.229 0 0145.4 65.658"
            data-name="Path 134"
            transform="translate(-10.643 -10.688)"
          ></path>
          <path
            d="M58 50.6h-6.983v6.983h3.561v-3.424H58z"
            data-name="Path 135"
            transform="translate(-14.685 -14.564)"
          ></path>
          <path
            d="M51.017 65.971v6.983H58v-3.561h-3.422v-3.422z"
            data-name="Path 136"
            transform="translate(-14.685 -18.989)"
          ></path>
          <path
            d="M65.553 72.954h6.983v-6.983h-3.56v3.423h-3.423z"
            data-name="Path 137"
            transform="translate(-18.869 -18.989)"
          ></path>
          <path
            d="M72.537 57.582V50.6h-6.984v3.56h3.423v3.423z"
            data-name="Path 138"
            transform="translate(-18.869 -14.564)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default AuditIcon;
