import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ContentItem, MediaItem } from '../../types';
import _ from 'lodash';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export type StaticImageProps = ContentItem & {
  image: MediaItem;
};

const Image = styled.img`
  width: 100%;
`;

const StaticImage = (props: StaticImageProps) => {
  const { image } = props;

  const [_id] = useState(_.uniqueId('static-image'));
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const q = gsap.utils.selector(containerRef);
    const tl = gsap.timeline();

    tl.from(q(`#${_id}`), { autoAlpha: 0, duration: 1 });

    ScrollTrigger.create({
      trigger: `#${_id}`,
      animation: tl,
      start: 'top 80%',
      end: 'bottom top',
    });
  }, [_id]);

  return (
    <div ref={containerRef}>
      {image?._url && <Image id={`${_id}`} src={image._url} />}
    </div>
  );
};
export default StaticImage;
