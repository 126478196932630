import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    teal30: '#D2E8EB',
    teal20: '#ADD1D7',
    teal10: '#A5C8CD',
    teal: '#A6D2D8',
    blue05: '#173C52',
    blue10: '#196F8E',
    blue20: '#295869',
    blue30: '#073D53',
    blue40: '#031A24',
    yellow: '#FDC300',
    red: '#E7302A',
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
    xxxl: '1920px',
  },
};

export default theme;
