import React, { FunctionComponent } from 'react';
import { ContentItem, PageContent } from '../types';
import { SplashProps } from '../components/splash/splash';
import Splash from '../components/splash/splash';
import ImageWithContent, {
  ImageWithContentProps,
} from '../components/content/image-with-content';
import LinkGroup, { LinkGroupProps } from '../components/link-group/link-group';
import Parallax, { ParallaxProps } from '../components/parrallax/parallax';
import ContentOnBackground, {
  ContentOnBackgroundProps,
} from '../components/content/content-on-background';
import Quote, { QuoteProps } from '../components/quote/quote';
import ImageGrid, { ImageGridProps } from '../components/image-grid/image-grid';
import ContactForm, {
  ContactFormProps,
} from '../components/contact-form/contact-form';
import InfoBox, { InfoBoxProps } from '../components/content/info-box';
import Goals, { GoalsProps } from '../components/goals/goals';
import TwoTextColumns, {
  TwoTextColumnsProps,
} from '../components/content/two-text-columns';
import ImageCaption, {
  ImageCaptionProps,
} from '../components/content/image-caption';
import Fleet, { FleetProps } from '../components/plane/fleet';
import StaticImage, {
  StaticImageProps,
} from '../components/content/static-image';
import TravelTime, {
  TravelTimeProps,
} from '../components/travel-time/travel-time';
import PlanYourFlight, {
  PlanYourFlightProps,
} from '../components/plan-your-flight/plan-your-flight';

type ViewProps = {
  pageContent: PageContent[];
};

function isSplash(obj: ContentItem): obj is SplashProps {
  return obj.contentTypeAlias === 'splash';
}

function isImageWithBackground(obj: ContentItem): obj is ImageWithContentProps {
  return (
    obj.contentTypeAlias === 'imageWithContent' ||
    obj.contentTypeAlias === 'challengeUs'
  );
}

function isLinkGroup(obj: ContentItem): obj is LinkGroupProps {
  return obj.contentTypeAlias === 'linkGroup';
}

function isParallax(obj: ContentItem): obj is ParallaxProps {
  return obj.contentTypeAlias === 'parallax';
}

function isContentOnBackground(
  obj: ContentItem,
): obj is ContentOnBackgroundProps {
  return obj.contentTypeAlias === 'contentOnBackground';
}

function isQuote(obj: ContentItem): obj is QuoteProps {
  return obj.contentTypeAlias === 'quote';
}

function isImageGrid(obj: ContentItem): obj is ImageGridProps {
  return obj.contentTypeAlias === 'imageGrid';
}

function isContactForm(obj: ContentItem): obj is ContactFormProps {
  return obj.contentTypeAlias === 'contactForm';
}

function isInfoBox(obj: ContentItem): obj is InfoBoxProps {
  return obj.contentTypeAlias === 'infobox';
}

function isGoals(obj: ContentItem): obj is GoalsProps {
  return obj.contentTypeAlias === 'goals';
}

function isImageCaption(obj: ContentItem): obj is ImageCaptionProps {
  return obj.contentTypeAlias === 'imageCaption';
}

function isTwoTextColumns(obj: ContentItem): obj is TwoTextColumnsProps {
  return obj.contentTypeAlias === 'twoTextColumns';
}

function isFleet(obj: ContentItem): obj is FleetProps {
  return obj.contentTypeAlias === 'fleet';
}

function isStaticImage(obj: ContentItem): obj is StaticImageProps {
  return obj.contentTypeAlias === 'staticImage';
}

function isTravelTime(obj: ContentItem): obj is TravelTimeProps {
  return obj.contentTypeAlias === 'travelTime';
}

function isPlanYourFlight(obj: ContentItem): obj is PlanYourFlightProps {
  return obj.contentTypeAlias === 'planYourFlight';
}

const View: FunctionComponent<ViewProps> = ({ pageContent }) => {
  return (
    <>
      {pageContent.map((content) => (
        <React.Fragment key={content._id}>
          {isSplash(content) && <Splash key={content._id} {...content} />}
          {isImageWithBackground(content) && (
            <ImageWithContent key={content._id} {...content} />
          )}
          {isContentOnBackground(content) && (
            <ContentOnBackground key={content._id} {...content} />
          )}
          {isLinkGroup(content) && <LinkGroup key={content._id} {...content} />}
          {isQuote(content) && <Quote key={content._id} {...content} />}
          {isImageGrid(content) && <ImageGrid key={content._id} {...content} />}
          {isParallax(content) && <Parallax key={content._id} {...content} />}
          {isContactForm(content) && (
            <ContactForm key={content._id} {...content} />
          )}
          {isInfoBox(content) && <InfoBox key={content._id} {...content} />}
          {isGoals(content) && <Goals key={content._id} {...content} />}
          {isImageCaption(content) && (
            <ImageCaption key={content._id} {...content} />
          )}
          {isTwoTextColumns(content) && (
            <TwoTextColumns key={content._id} {...content} />
          )}
          {isFleet(content) && <Fleet key={content._id} {...content} />}
          {isStaticImage(content) && (
            <StaticImage key={content._id} {...content} />
          )}
          {isTravelTime(content) && (
            <TravelTime key={content._id} {...content} />
          )}
          {isPlanYourFlight(content) && (
            <PlanYourFlight key={content._id} {...content} />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default View;
