import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ContentItem, MediaItem } from '../../types';
gsap.registerPlugin(ScrollTrigger);

export type QuoteProps = ContentItem & {
  image: MediaItem;
  quote: string;
  quoteRichText?: string;
  author?: string;
  authorPosition?: string;
};

const Container = styled.div`
  min-height: 460px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  color: white;

  padding: 50px 0;
`;

const QuoteContent = styled.p`
  font-size: 28px;
  font-style: italic;
  color: white;
  width: 100%;
  display: inline-block;
  line-height: 30px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    width: 52%;
  }
`;

const QuoteMarks = styled.div`
  font-size: 80px;
  font-style: italic;
  font-weight: bold;
  line-height: 60px;
  margin-bottom: -10px;
`;

const Quote: FunctionComponent<QuoteProps> = ({
  image,
  quote,
  quoteRichText,
  author,
  authorPosition,
}) => {
  useEffect(() => {
    const tl = gsap.timeline();
    tl.from('#quote-container > *', { autoAlpha: 0, x: 20, stagger: 0.45 });
    tl.from('#end-quote-marks', { autoAlpha: 0, x: 20, stagger: 0.45 });

    ScrollTrigger.create({
      trigger: '#outer-quote-container',
      animation: tl,
      start: 'top 70%',
      end: 'bottom top',
    });
  }, []);

  const _quoteRichText = quoteRichText?.replace(
    new RegExp('</p>$'),
    '<span class="end-quote-marks" style="margin-left: 4px">”</span></p>',
  );

  return (
    <Container
      style={{ backgroundImage: `url("${image._url}")` }}
      id={'outer-quote-container'}
    >
      <div className={'helio-container'} id={'quote-container'}>
        <QuoteMarks>“</QuoteMarks>
        <QuoteContent>
          {_quoteRichText ? (
            <div
              dangerouslySetInnerHTML={{
                __html: _quoteRichText,
              }}
            />
          ) : (
            quote
          )}
          {!quoteRichText && (
            <div className={'end-quote-marks'} id={'end-quote-marks'}>
              ”
            </div>
          )}
        </QuoteContent>

        {author && <div>{author}</div>}
        {authorPosition && (
          <div style={{ fontSize: '14px' }}>{authorPosition}</div>
        )}
      </div>
    </Container>
  );
};

export default Quote;
