import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ButtonCard, { ButtonCardIcons } from '../button/button-card';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ButtonLink } from '../button/button';
import { ContentItem, MediaItem } from '../../types';
import _ from 'lodash';
gsap.registerPlugin(ScrollTrigger);

export type LinkCard = {
  icon: ButtonCardIcons;
  headline: string;
  linkCardContent: string;
  cardLink: ContentItem;
};

export type LinkGroupProps = ContentItem & {
  id: string;
  header: string;
  linkGroupContent: string;
  linkGroupHeader: string;
  image: MediaItem;
  cards: LinkCard[];
  buttonText?: string;
  buttonUrl?: ContentItem;
};

const LinkGroupBackground = styled.div<{ image: string }>`
  display: flex;
  flex-direction: column;

  background-image: ${({ image }) => `url("${image}")`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 100vw;
  overflow-x: hidden;

  color: white;
  padding: 120px 0 60px;

  button {
    color: white;
  }
`;

const CardContainer = styled.div`
  width: 100%;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.md}) {
    width: 50%;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxl}) {
    width: 25%;
  }
`;

const ButtonLinkContainer = styled.div`
  padding: 20px 0 40px;
`;

const LinkGroup: FunctionComponent<LinkGroupProps> = (props) => {
  const [id] = useState(_.uniqueId('link-group'));
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    image,
    linkGroupContent,
    linkGroupHeader,
    header,
    cards,
    buttonText,
    buttonUrl,
  } = props;

  useEffect(() => {
    const q = gsap.utils.selector(containerRef);
    const tl = gsap.timeline();
    tl.from(q(`#${id} > *`), { autoAlpha: 0, x: 20, stagger: 0.2 });

    ScrollTrigger.create({
      trigger: `#${id}`,
      animation: tl,
      start: 'top 70%',
      end: 'bottom top',
    });
  }, [id]);

  return (
    <LinkGroupBackground image={image?._url} ref={containerRef}>
      <div className={'helio-container link-group'} id={id}>
        <h2>{header}</h2>
        <div dangerouslySetInnerHTML={{ __html: linkGroupContent }} />
        <p>
          <b>{linkGroupHeader}</b>
        </p>

        {buttonText && buttonUrl && (
          <ButtonLinkContainer>
            <ButtonLink darkBackground to={buttonUrl._url}>
              {buttonText}
            </ButtonLink>
          </ButtonLinkContainer>
        )}

        <div className="row">
          {cards.map((card, index) => (
            <CardContainer key={index}>
              <ButtonCard {...card} id={index}>
                <div
                  dangerouslySetInnerHTML={{ __html: card.linkCardContent }}
                />
              </ButtonCard>
            </CardContainer>
          ))}
        </div>
      </div>
    </LinkGroupBackground>
  );
};

export default LinkGroup;
