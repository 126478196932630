// import original module declarations
import 'styled-components';
import { ContentTheme } from './types';
import { DefaultTheme } from 'styled-components';

// and extend them!
declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      teal30: string;
      teal20: string;
      teal10: string;
      teal: string;
      blue05: string;
      blue10: string;
      blue20: string;
      blue30: string;
      blue40: string;
      yellow: string;
      red: string;
    };
    breakpoints: {
      xxxl: string;
      xxl: string;
      xl: string;
      lg: string;
      md: string;
      sm: string;
    };
  }
}

export const mapThemeToColor = (
  contentTheme: ContentTheme,
  theme: DefaultTheme,
): string => {
  if (contentTheme === 'yellow') return theme.colors.yellow;
  if (contentTheme === 'blue') return theme.colors.blue30;
  if (contentTheme === 'teal') return theme.colors.teal30;
  return 'white';
};
