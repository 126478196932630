import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const ButtonStyle = css<{
  darkBackground?: boolean;
  withBackgroundColor?: boolean;
}>`
  display: inline-flex;
  gap: 8px;
  text-align: center;
  align-items: center;
  background-color: ${({ withBackgroundColor, theme: { colors } }) =>
    withBackgroundColor ? 'white' : 'transparent'};
  border: 5px solid ${({ theme: { colors } }) => colors.yellow};
  border-radius: 37px;
  font-weight: 800;

  padding: 8px 30px;
  color: ${({ darkBackground }) => (darkBackground ? 'white' : 'black')};
  transition: background-color 0.3s;

  &:hover {
    color: ${({ darkBackground }) => (darkBackground ? 'white' : 'black')};
    background-color: ${({ theme: { colors } }) => colors.yellow};
    text-decoration: none;
  }

  font-size: 17px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxxl}) {
    font-size: 20px;
  }
`;

const Button = styled.button<{
  darkBackground?: boolean;
  withBackgroundColor?: boolean;
}>`
  ${ButtonStyle}
`;

export const YellowThemeExternalLink = styled.a<{
  darkBackground?: boolean;
  withBackgroundColor?: boolean;
}>`
  ${ButtonStyle}

  &:hover {
    color: black;
    background-color: white;
    text-decoration: none;
  }
`;

export const ExternalLink = styled.a<{
  darkBackground?: boolean;
  withBackgroundColor?: boolean;
}>`
  ${ButtonStyle};

  svg {
    width: 20px;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    svg {
      width: 32px;
    }
  }
`;

export const ButtonLink = styled(Link)<{
  darkBackground?: boolean;
  withBackgroundColor?: boolean;
}>`
  ${ButtonStyle}
`;

export default Button;
