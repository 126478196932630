import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

type ScrollToTopProps = {
  children?: React.ReactNode;
};

const ScrollToTop = ({ children }: ScrollToTopProps) => {
  const location = useLocation();
  useEffect(() => {
    // @ts-ignore
    window.scrollTo({ left: 0, top: 0, behavior: 'instant' });
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
