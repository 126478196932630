import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { ContentItem, MediaItem } from '../../types';
gsap.registerPlugin(ScrollTrigger);

export type ParallaxProps = ContentItem & {
  image: MediaItem;
};

const ParallaxContainer = styled.div<{ image: string }>`
  background-color: grey;
  width: 100%;
  height: 300px;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    height: 500px;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxl}) {
    height: 600px;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xxxl}) {
    height: 700px;
  }

  background-image: ${({ image }) => `url("${image}")`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const Parallax: FunctionComponent<ParallaxProps> = ({ image }) => {
  useEffect(() => {
    const tl = gsap.timeline();
    tl.to('.parallax', { backgroundPosition: '20% 100%' });

    ScrollTrigger.create({
      trigger: '.parallax',
      start: 'top bottom',
      end: 'bottom top',
      toggleActions: 'play play reverse reverse',
      animation: tl,
      scrub: true,
    });
  }, []);

  return <ParallaxContainer className={'parallax'} image={image?._url} />;
};

export default Parallax;
