import React from 'react';

function HelicopterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="89.999"
      data-name="Group 123"
      viewBox="0 0 90 89.999"
    >
      <g fill="#fff" data-name="Group 121">
        <path
          d="M81.484 48.809l-.06-.595-.5.335a3.044 3.044 0 01-1.576.414c-.656 0-10.66.243-19.306.456A36.766 36.766 0 0067.7 38.561c1.329-2.856-.088-4.395-.162-4.471s-1.6-1.478-4.459-.15c-2.028.944-5.741 2.67-11.254 8.04l.491-19.947a1.851 1.851 0 00-1.884-1.879c-.991-.008-1.435 0-1.52.006l-.595.059.335.5a3.048 3.048 0 01.414 1.575c0 .8.362 15.512.522 21.972l-.078.082c-.2.215-.407.43-.617.658l-.036.038a2.869 2.869 0 00-3.007.658l-2.567 2.567a2.844 2.844 0 00-.794 1.56l-20.354-.5a1.851 1.851 0 00-1.879 1.883c-.008.991 0 1.436.006 1.52l.06.595.5-.335a3.043 3.043 0 011.575-.414c.681 0 11.457-.262 20.527-.486-1.82 2.547-2.517 4.336-2.365 6.056l-9.917 10.958-4.4-1.55-1.585 1.584 3.643 2.554-4.657 5.147 1.757 1.753.252-.252.663-.662.251-.251-.535-.535 3.923-3.549 2.546 3.643 1.588-1.588-1.55-4.4L43.5 61.084c1.75.152 3.561-.566 6.168-2.446a2.877 2.877 0 00.273.177l-.51 20.685a1.851 1.851 0 001.884 1.879h.831c.422 0 .632 0 .689-.01l.595-.06-.335-.5a3.037 3.037 0 01-.414-1.575c0-.758-.328-14.137-.478-20.18a2.852 2.852 0 001.176-.71l2.567-2.567a2.865 2.865 0 00.657-3.007l.036-.034c.4-.365.776-.721 1.143-1.072l21.828.536a1.851 1.851 0 001.879-1.883c.009-.991 0-1.436-.006-1.52"
          data-name="Path 302"
          transform="translate(-5.831 -5.8)"
        ></path>
        <path
          d="M45 0a45 45 0 1045 45A45.051 45.051 0 0045 0m0 84.3A39.3 39.3 0 1184.3 45 39.347 39.347 0 0145 84.3"
          data-name="Path 303"
        ></path>
        <path
          d="M6.813 0L0 6.813"
          data-name="Line 4"
          transform="translate(17.245 69.163)"
        ></path>
        <path
          d="M0 0H9.635V0.712H0z"
          data-name="Rectangle 70"
          transform="rotate(-45 99.905 17.35)"
        ></path>
      </g>
    </svg>
  );
}

export default HelicopterIcon;
