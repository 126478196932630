import React from 'react';

function LinkedInIconBlack() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            d="M12.154-11.778H33.2v-21.8H12.154z"
            data-name="Path 39"
            transform="translate(-12.154 33.576)"
          ></path>
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            fill="none"
            d="M0-.354h45v-45H0z"
            data-name="Path 41"
            transform="translate(0 45.354)"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 12" transform="translate(0 45.354)">
        <g data-name="Group 9" transform="translate(12.977 -34.753)">
          <g data-name="Group 7" transform="translate(7.246 6.617)">
            <path
              d="M0 0c0-4.3 5.52-4.648 5.52 0v7.734h4.14v-9.328c0-7.249-7.734-6.985-9.66-3.417v-2.435h-4.14v15.18H0z"
              data-name="Path 36"
              transform="translate(4.14 7.447)"
            ></path>
          </g>
          <path
            d="M12.5-11.778h4.14v-15.18H12.5z"
            data-name="Path 37"
            transform="translate(-12.154 33.576)"
          ></path>
          <g data-name="Group 8" transform="translate(.001 -.001)">
            <path
              d="M0 0a2.425 2.425 0 002.415-2.434A2.426 2.426 0 000-4.869a2.426 2.426 0 00-2.415 2.435A2.425 2.425 0 000 0"
              data-name="Path 38"
              transform="translate(2.415 4.869)"
            ></path>
          </g>
        </g>
        <g data-name="Group 11" transform="translate(0 -45.354)">
          <g data-name="Group 10" transform="translate(2.481 2.48)">
            <path
              fill="none"
              stroke={'white'}
              strokeWidth="5"
              d="M20.02 40.039a20.019 20.019 0 0020.02-20.02A20.019 20.019 0 0020.02 0 20.019 20.019 0 000 20.02a20.019 20.019 0 0020.02 20.019z"
              data-name="Path 40"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LinkedInIconBlack;
