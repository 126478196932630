import React, { FunctionComponent, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ButtonLink } from '../button/button';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ContentItem, MediaItem } from '../../types';
gsap.registerPlugin(ScrollTrigger);

export type ContentOnBackgroundProps = ContentItem & {
  header: string;
  subHeader?: string;
  mainContent: string;
  link?: ContentItem;
  linkText?: string;
  backgroundImage: MediaItem;
};

const Column = styled.div`
  a {
    color: black;
    font-weight: bold;
  }

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    width: 50%;
  }
`;

const Container = styled.div<{ image: string }>`
  padding: 90px 0 290px;

  background-image: ${({ image }) => `url("${image}")`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    padding: 180px 0 380px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  max-width: 100vw;
  overflow-x: hidden;
`;

const ContentOnBackground: FunctionComponent<ContentOnBackgroundProps> = (
  props,
) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { header, subHeader, mainContent, linkText, link, backgroundImage } =
    props;

  useEffect(() => {
    const q = gsap.utils.selector(containerRef);
    const tl = gsap.timeline();
    tl.from(q('.content-column > *'), {
      autoAlpha: 0,
      x: 20,
      duration: 1,
      stagger: 0.2,
    });

    ScrollTrigger.create({
      trigger: '.content-on-background',
      animation: tl,
      start: 'top 70%',
      end: 'bottom top',
    });
  }, []);

  return (
    <Container
      image={backgroundImage?._url}
      ref={containerRef}
      className={'content-on-background'}
    >
      <div className={'helio-container'}>
        <Column className={'content-column'}>
          <h2>{header}</h2>
          {subHeader && (
            <p>
              <b>{subHeader}</b>
            </p>
          )}
          <div
            dangerouslySetInnerHTML={{ __html: mainContent }}
            style={{ paddingBottom: '20px' }}
          />

          {link?._url && <ButtonLink to={link._url}>{linkText}</ButtonLink>}
        </Column>
      </div>
    </Container>
  );
};

export default ContentOnBackground;
