import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ContentItem, MediaItem } from '../../types';

export type ImageGridProps = ContentItem & {
  images: MediaItem[];
};

const ImageContainer = styled.div`
  width: 100vw;
  height: 300px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media all and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xl}) {
    width: 50%;
    height: 620px;
  }
`;

const ImageGrid: FunctionComponent<ImageGridProps> = ({ images }) => {
  return (
    <div className={'row'} style={{ margin: '0' }}>
      {images.map((image, index) => (
        <ImageContainer
          style={{ backgroundImage: `url("${image._url}")` }}
          key={index}
        />
      ))}
    </div>
  );
};

export default ImageGrid;
